.qsearch {
  z-index: 1;
  position: absolute;
  top: 15px !important;
  margin-left: 115px !important;
  margin-right: 80px !important;
}

@media only screen and (max-width: 768px) {
  .qsearch {
    margin-left: 13px !important;
  }
  .navbar-nav .open .dropdown-menu {
    background-color: white !important;
    position: absolute !important;
    border: none !important;
    z-index: 10 !important;
  }
}

#navBarFormButton:disabled {
  background-color: white !important;
  color: grey !important;
  opacity: 1 !important;
}

.form-control {
  background-color: white !important;
  opacity: 1 !important;
  top: 2px !important;
  box-shadow: none !important;
}

.form-control[disabled] {
  background-color: white !important;
  opacity: 1 !important;
}

.advancedfilter {
  border-radius: 20px !important;
  /* background-color: #9C1804 !important; */
  background-color: yellow !important;
}
.dropdown-menu {
  /* border-radius: 12px !important; */
  font-family: "Roboto", sans-serif;
  color: #2b2b2b;
  min-width: 140px !important;
}
.dropdown-menu:hover {
  cursor: pointer !important;
}
.dropdown-menu > li {
  padding: 4 0 4 0;
}
