.load-bar {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 8px;
  background-color: var(--primary-color);
  top: 0%;
}
.bar {
  content: "";
  display: inline;
  position: absolute;
  width: 0;
  height: 100%;
  left: 50%;
  text-align: center;
}
.bar:nth-child(1) {
  background-color: var(--secondary-color);
  animation: loading 3s linear infinite;
}
.bar:nth-child(2) {
  background-color: #c0c9db;
  animation: loading 3s linear 1s infinite;
}
.bar:nth-child(3) {
  background-color: var(--primary-color);
  animation: loading 3s linear 2s infinite;
}
@keyframes loading {
  from {
    left: 50%;
    width: 0;
    z-index: 100;
  }
  33.3333% {
    left: 0;
    width: 100%;
    z-index: 10;
  }
  to {
    left: 0;
    width: 100%;
  }
}
