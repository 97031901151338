.exportPanels {
  z-index: 1;
  position: absolute;
  bottom: 8px;
  left: 66px;
  width: 400px;
  height: auto;
}
.exportPanelsBody {
  max-height: calc(100vh - 250px);
  overflow-y: auto;
}
.btn_select {
  margin: 0px 5px 0 0px;
  height: 33px;
  background-color: var(--white-color);
  border-color: var(--primary-color);
}
.btn_submit {
  margin: 24px 5px 0 20px;
  height: 33px;
}

@media (max-width: 1024px) {
  .exportPanels {
    z-index: 1;
    position: absolute;
    bottom: 8px;
    left: 66px;
    width: 400px;
    height: auto;
  }
  .exportPanelsBody {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
  }
}
@media (max-width: 992px) {
  .exportPanels {
    z-index: 503;
    border: 1px solid black;
    position: absolute;
    border: 0px;
    right: 10%;
    left: 10%;
    bottom: 6px;
    width: 50%;
  }
  .exportPanelsBody {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
  }
}
@media (max-width: 700px) {
  .exportPanels {
    z-index: 503;
    border: 1px solid black;
    position: absolute;
    border: 0px;
    right: 10%;
    left: 10%;
    bottom: 5px !important;
    width: 60%;
  }
  .exportPanelsBody {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
  }


  .map {
    width: 100%;
    height: 400px;
  }
  a.skiplink {
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0;
    border: 0;
    height: 1px;
    width: 1px;
    overflow: hidden;
  }
  a.skiplink:focus {
    clip: auto;
    height: auto;
    width: auto;
    background-color: #fff;
    padding: 0.3em;
  }
  #map:focus {
    outline: #4A74A8 solid 0.15em;
  }
}
