.loginContainer {
  background: url("../../../asset/jpg/loginBg.jpg") no-repeat center center
    fixed;
  background-size: cover;
  height: 100%;
  width: 100%;
  position: absolute;
}
.loginText {
  border-radius: 2.5px !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border: none;
  border: 1px solid #b8b5b5;
  background: rgba(173, 173, 173, 0.99);
  outline: none;
}
.loginButton {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  transition: box-shadow 0.2s;
  outline: none;
  border-radius: 4px;
  display: inline-block;
  box-sizing: border-box;
  background-color: var(--primary-color) !important;
  color: var(--white-color) !important;
}
.loginContainer button[disabled] {
  background-color: var(--primary-color) !important;
  opacity: 1;
}
.captcha {
  margin: 5px auto;
  align-items: center;
  display: table;
}
.appTitle {
  width: 100%;
  font-size: 2.5em;
  margin-top: 10px;
  /* background: #1d75bc; */
  background: rgba(252, 252, 252, 0.99);
  text-align: center;
  margin-bottom: 10px;
  border-radius: 12px 12px 0px 0px;
  /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); */
  color: var(--primary-color);
}
.withoutappTitle {
  width: 100%;
  font-size: 2.5em;
  margin-top: 10px;
  background: transparent;
  text-align: center;
  margin-bottom: 10px;
  border-radius: 12px 12px 0px 0px;
  color: var(--white-color);
}
.withoutLogin {
  position: absolute;
  bottom: 20%;
  width: 90%;
  right: 5%;
  left: 5%;
  height: 300px;
  margin: auto;
  background: transparent;
  border-radius: 12px;
  transition: all 0.2s ease-in-out;
}
.login {
  position: absolute;
  bottom: 20%;
  width: 90%;
  right: 5%;
  left: 5%;
  height: 320px;
  margin: auto;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  background: rgba(252, 252, 252, 0.99);
  border-radius: 12px;
  transition: all 0.2s ease-in-out;
}
.loginLabel {
  font-weight: bold;
  color: #1d75bc;
}

/* @media only screen and (min-width: 320px) {
  .login {
    right: 20%;
    left: 20%;
    width: 80%;
    border-radius: 12px;
    bottom: 27%;
  }
  .loginmain {
    right: 20%;
    left: 20%;
    width: 40%;
    border-radius: 12px;
    bottom: 27%;
  }
  .loginFormTwo {
    margin-left: 25px;
    margin-right: 25px;
  }
} */

@media only screen and (min-width: 480px) {
  .login {
    width: 40%;
    border-radius: 12px;
    bottom: 27%;
  }
  .loginmain {
    right: 20%;
    left: 20%;
    width: 40%;
    border-radius: 12px;
    bottom: 27%;
  }
  .login form {
    margin: 0 auto;
    max-width: 320px;
  }
}

/* @media only screen and (min-width: 768px) {
  .login {
    width: 52%;
    border-radius: 12px;
    bottom: 27%;
  }
} */

@media only screen and (min-width: 1080px) {
  .login {
    /* right: 30%;
    left: 30%; */
    width: 30%;
    border-radius: 12px;
    bottom: 27%;
  }
  .login form {
    margin: 0 auto;
    max-width: 320px;
  }
}
@media only screen and (min-width: 1400px) {
  .login {
    right: 30%;
    left: 30%;
    width: 25%;
    border-radius: 12px;
    bottom: 27%;
  }

  .login form {
    margin: 0 auto;
    max-width: 320px;
  }
}
