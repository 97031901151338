.search-popup{
    position: absolute;
    z-index: 2;
    background-color: white;
    -webkit-filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
    filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
    padding: 0px;
    border-radius: 15px;
    /* bottom: 12px; */
    /* left: -50px; */
    min-width: 280px;
    top: 23%;
    right: 4%;
}