.listStyleUl {
  list-style: square;
  list-style-position: outside;
  padding-left: 2px;
}
.listStyleLi {
  padding-left: 6px;
}
.getCountPanels {
  z-index: 1;
  position: absolute;
  border: 0px;
  right: 1em;
  bottom: 3em;
  margin: auto;
  width: 300px;
  margin-top: 5px;
}
.getCountPanelsBody {
  max-height: calc(80vh - 250px);
  overflow-y: auto;
  padding: 4px 0px 10px 30px;
}
@media (max-width: 992px) {
  .getCountPanels {
    z-index: 503;
    border: 1px solid black;
    position: absolute;
    border: 0px;
    right: 10%;
    left: 10%;
    bottom: 3% !important;
    width: 50%;
  }
  .getCountPanelsBody {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
  }
}
@media (max-width: 700px) {
  .getCountPanels {
    z-index: 503;
    border: 1px solid black;
    position: absolute;
    border: 0px;
    right: 10%;
    left: 10%;
    bottom: 3% !important;
    width: 60%;
  }
  .getCountPanelsBody {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
  }
}
