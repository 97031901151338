@media only screen and (max-width: 992px) {
  #toolbar .nav li {
    display: inline;
    float: right;
    top: 9px;
  }
  #toolbar .nav li a {
    display: inline;
  }
}
@media only screen and (max-width: 768px) {
  #toolbar .nav li {
    display: inline;
    float: left;
  }
  #toolbar .nav li a {
    display: inline;
    /* left: 400px; */
    top: 9px;
  }
  /* #toolbar ul .navbar-nav {
    margin:0%;
  } */
}

.nav-scroller-wrapper {
  position: relative;
  overflow: hidden;
  padding: 0 30px;
}

.nav-scroller {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  font-size: 0;

  padding-bottom: 20px;
  margin-bottom: -20px;
}

.nav-scroller-content {
  position: relative;
  display: flex;
  justify-content: space-around;
  float: left;
  width: min-content;
  min-width: 100%;
  transition: transform 0.4s ease-in-out;
}

.no-transition {
  transition: none;
}

.nav-scroller-item {
  display: block;
  font-size: 1.125rem;
  text-transform: uppercase;
  text-align: center;
  padding: 0.5rem;
}

.nav-scroller-btn {
  position: absolute;
  top: 0;
  bottom: 0;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 1.25rem;
  transition: opacity 0.3s;

  /* &:not(.active) {
    opacity: 0;
    pointer-events: none;
  } */
}

.nav-scroller-btn--left {
  left: 0;
}

.nav-scroller-btn--right {
  right: 0;
}

.fullwidth {
  padding: 15px 0;
  margin: 15px 0;
  background-color: var(--secondary-color);
  position: absolute;
  z-index: 1;
}
