
.resultModal {
  position: absolute !important;
  z-index: 1000 !important;
  top: 162px;
  left: 470px;
  width: 350 !important;
  border-radius: 15px;
  box-shadow: none !important;
  border: none !important;
}
.resultModal .modal.in .modal-dialog {
  width: 350px !important;
  border-radius: 15px !important;
}
.resultModal .modal-content {
  border-radius: 18px !important;
  width: 350px !important;
  align-content: center;
}
.resultModalPanel {
  margin-bottom: 0px;
  width: 350;
}
@media only screen and (max-width: 768px) {
  .resultModal {
    top: 150px;
    left: 0px;
    overflow-x: scroll;
    width: 327px !important;
  }
  .resultModal .modal.in .modal-dialog {
    width: 312px !important;
  }
  .resultModal .modal-content {
    width: 312px !important;
  }
  .advancedFilterPanel {
    width: 312px !important;
  }
}

.panelBody {
  max-height: calc(70vh - 170px);
  min-height: 60px;
  overflow-y: auto;
  border-radius: 15px !important;
}
/* width */
::-webkit-scrollbar {
  width: 12px;
}
.report {
  margin-left: 3px;
}
.addfilterbtn {
  padding: 3px;
  border-color: var(--secondary-color) !important;
  background-color: var(--secondary-color) !important;
  margin-left: 15px !important;
  color: var(--primary-color);
}

.panelBody {
  max-height: calc(70vh - 170px);
  min-height: 60px;
  overflow-y: auto;
  border-radius: 15px !important;
}
/* width */
::-webkit-scrollbar {
  width: 12px;
}
.resultbutton {
  display: block;
  margin: auto;
}
