.bufferReportContainerModel {
  max-height: 300px !important;
  /* min-height: 261px !important; */
  overflow-y: auto;
  overflow-x: auto;
}
.excelName {
  padding: 7px 15px;
  margin: 8px 3px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.excelNam {
  padding: 7px 15px;
  margin: 8px 3px;
  display: inline-block;
  font-weight: bold;
  box-sizing: border-box;
}
.jumbo {
  padding: 0 0 0 0 !important;
  background-color: #ccc;
}
.pdfModelResult {
  margin-top: 70px;
  border-radius: 10% 10% 10% 10%;
}
.pdfModelResult .modal-open .modal {
  overflow-y: hidden !important;
}
.pdfModelResultHeader {
  color: white;
  background-color: var(--secondary-color);
  text-align: center;
  border-radius: 15px 15px 0 0;
}
.pdfModelResultFooter {
  color: var(--colorsecondary1);
  border-color: var(--colorprimary);
}
.pdfheadbtn {
  background-color: var(--secondary-color) !important;
  color: var(--secondary-color) !important;
  border: none;
  margin-top: 5px !important;
}
.modal-content {
  border-radius: 18px !important;
}
