.analysis-cards{
    display: grid;
    grid-template-columns: repeat(4,1fr);
}
 
.road-icon-button{
    width: 40%;
    height: 8%;
    border: none;
    background: none;
}
 
 
.show-report-btn{
    font-size: 1.5rem;
    color: white;
    background-color: #cb3f3f;
    padding: 0.7rem;
    border-radius: 0.3rem;
    border: none;
}
 
.analysis-form-group{
    /* font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem; */
    font-size: 13px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0px;
   margin-top: 2px;
}

.analysis-form-group label{
    margin-bottom: 0px !important;
}

.analysis-form-group select{
    margin: 0px !important;
    height: 24px;
}
 
.left-analysis-dropdown{
    /* width: 17%;
    margin: 0; */
    width: 17%;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
}
 
.left-first-div{
    width: 100%;
    display: flex;
    flex-direction: column;
    border: none;
    padding: 0px;
}
 
.heading-div{
    /* width: 100%;
    display: flex;
    justify-content: center;
    border-bottom: 3px solid black;
     margin-block: 1rem; 
    margin-block: 0px; */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    margin-block: 0px;
}

 .hr-tag{
    border: 1px solid black;
    width: 72%;
 }

.left-row-head{
    /* font-size: 21px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    margin-top: 1px !important;
    letter-spacing: .1rem; */
    font-size: 21px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    letter-spacing: .1rem;
    margin: 0px;
}
 
.center-analysis-div{
    width: 70%;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}
 
.center-top-div{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: center;
}
 
.map-div{
    border: 2px solid;
    height: 50%;
    width: 100%;
}
 
.center-bottom-div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
 
.center-bottom-dropdown{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
 
.right-analysis-div{
    width: 17%;
    margin: 0;
}
 
.analysis-buttons{
    padding: 1rem;
    background-color: #e13737;
    border: none;
    border-radius: 0.5rem;
    color: white;
    cursor: pointer;
    width: 100%;
}

.analysis-buttons:hover {
    background-color: #e13737; 
  }
 
  .analysis-buttons:disabled {
    background-color: #e13737;
   }
.range-text{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.center-dropdowns-row{
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Analysis Style */
.analysis-row{
    width: 100%;
    display: flex;
    justify-content: center;
    border-block: 4px solid grey;
}

.analysis-heading{
    font-size: 3rem;
    font-weight: 600;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.top-analysis-div{
  display: flex;
    justify-content: center;
}

.analysis-second-div{
    display: flex;
    gap: 1rem;
    max-height: 422px;
}
.analysis-dropdown-head{
  border-bottom: 4px solid #20130f;
  margin-bottom: 1rem;
}

.center-map-row{
    display: flex;
    align-items: center;
    justify-content: center;
    /* max-height: 349px; */
}

.left-row{
  width: 15%;
  padding: 0.6rem;
}

.right-row{
  width: 15%;
  padding: 0.6rem;
}

.show-report-btn{
  margin-right: 4rem;
  width: 14%;
  padding: 0.8rem;
  background-color: #ec3131;
  outline: none;
  border: none;
  border-radius: 0.6rem;
  /* font-size: 1.6rem; */
  /* font-weight: 600; */
  color: white;
 cursor: pointer;
}
.show-report-btn:disabled{
  background-color: #df5c5c;
  cursor: not-allowed;
}

/* --------------- */

.analysis-main-row{
    display: flex;
    width: 100%;
    gap: 7px;
}

.analysis-left-dropdown{
    /* border: 1px solid; */
    /* flex-direction: column; */
    padding-left: 5px;
    width: 20%;
}

.analysis-left-head{
    font-size: 13px !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    font-weight: 700;
}

.analysis-head-line{
    border: 1px solid #838383;
    width: 85%;
    margin-top: 1px !important;
    margin-bottom: 4px !important;
}

.analysis-left-dropdowns{

}

.analysis-form-dropdown{
    margin-bottom: 0px !important;
    width: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.analysis-form-dropdown label{
    font-size: 12px;
    margin-bottom: 1px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.analysis-form-dropdown-input{
    font-size: 11px;
    /* font-weight: 100; */
    margin-bottom: 5px;
    max-height: 25px;
    width: 90% !important;
    
}

.analysis-btn-div{
    position: absolute;
    /* border: 1px solid; */
    bottom: 0px;
    width: 16%;
}

.analysis-btn{
    background-color: #e13737;
    border: none;
    border-radius: 0.5rem;
    color: white;
    cursor: pointer;
    width: 100%;
    height: 38px;
    margin-block: 2rem;
}

.analysis-center-dropdown{
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.analysis-center-head-line{
    border: 1px solid #838383;
    width: 85%;
    margin-top: 1px !important;
    margin-bottom: 4px !important;
}

.analysis-center-first-dropdowns{
    display: flex;
    align-items: center;
    gap: 4px;
    margin-inline: 4px;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.analysis-center-second-dropdowns{

}