.settingsBtn {
  padding: 3px;
  border-color: var(--white-color) !important;
  background-color: var(--white-color) !important;
  margin-left: 15px !important;
  margin-top: 5px;
  margin-bottom: 7px;
  color: #2b2b2b;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  outline: none;
}
.settingsBtn:hover {
  color: var(--primary-color);
}
.settingsBtn:focus {
  outline-style: none !important;
  box-shadow: none;
  outline: none !important;
}
.settingsImg {
  margin: -4px -7px 0 17px;
}
.settingsImg2 {
  margin: -4px -7px 0 17px;
  width: 15px;
  height: 17px;
}

.localbodyBtn {
  padding: 3px;
  border-color: var(--white-color) !important;
  background-color: var(--white-color) !important;
  margin-left: 15px !important;
  margin-top: 5px;
  margin-bottom: 7px;
  color: #2b2b2b;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  outline: none;
}
.localbodyBtn:disabled {
  color: #2b2b2b !important;
}
.localbodyBtn:focus {
  outline-style: none !important;
  box-shadow: none;
  outline: none !important;
}
.localbodyImg {
  margin: -4px -7px 0 17px;
}

.cngpass {
  top: 162px;
  left: 470px;
  width: 426px !important;
  border-radius: 15px;
  box-shadow: none !important;
  border: none !important;
}
.cngpassmodal {
  top: 97px;
  margin-bottom: 0px;
  width: 426px;
  left: 35%;
}
.panelBody {
  max-height: calc(70vh - 170px);
  min-height: 60px;
  overflow-y: auto;
  border-radius: 15px !important;
}
/* .pull-btn{
    margin-left: 320 !important;
  } */
