@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.resultModal{
    position: absolute;
    z-index: 1;
    cursor: pointer;
}

.save-model-body{
    display: flex !important;
}


/* polygonTable */

.polygonTable{

}

.polygonTable .modal-dialog{
    position: absolute;
    right: 2%;
    width: 22%;
}

.polygonTablePanel{
    margin: 0px !important;
    position: absolute;
    z-index: 1;
    top: 15%;
    right: 3%;
}

.polygonTableRow{
    border: 1px solid #808080a1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-inline: 1px;
    border-radius: 4px;
    padding: 2px 0px 3px 0px;
}

.polygonTableHeading{
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-variation-settings: "slnt" 0;
    font-size: 14px;
}

.polygonTableIcon{
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 17px;
    justify-content: flex-end;
}

.polygonTableButton{
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.polygonTableButton Button{
    background-color: #ef9385 !important;
    color: var(--white-color) !important;
    outline: none !important;
}

.polygonTableButton Button:hover{
    background-color: var(--primary-color) !important;
    color: var(--white-color) !important;
}



@media screen and (max-width: 900px) {
   .polygonTable .modal-dialog{
    position: absolute;
    right: 2%;
    width: 31%;
    }
    
}

@media screen and (max-width: 700px) {
    .polygonTable .modal-dialog{
     position: absolute;
     right: 2%;
     width: 43%;
     }
     
 }