.addassetpanel {
  z-index: 1;
  position: absolute;
  bottom: 40px;
  left: 66px;
  width: 253px;
  height: 163px;
}

.addassetpanelButton {
  width: 50%;
  margin-left: 25%;
  justify-content: center;
  display: flex;
  margin-top: 24px;
}

@media (max-width: 992px) {
  .addassetpanel {
    z-index: 503;
    border: 1px solid black;
    position: absolute;
    border: 0px;
    right: 10%;
    left: 10%;
    bottom: 3% !important;
    width: 50%;
  }
  .addassetpanelsBody {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
  }
}
@media (max-width: 700px) {
  .addassetpanel {
    z-index: 503;
    border: 1px solid black;
    position: absolute;
    border: 0px;
    right: 10%;
    left: 10%;
    bottom: 3% !important;
    width: 60%;
  }
  .addassetpanelsBody {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
  }
}
