.marginSelect {
  margin-left: 10px;
  margin-right: 10px;
}
.marginRange {
  margin-top: 10px;
}
.marginLabel {
  margin-top: 10px;
}

.bufferPanels {
  z-index: 1;
  position: absolute;
  bottom: 8px;
  left: 66px;
  width: 350px;
  height: auto;
}
@media (max-width: 992px) {
  .bufferPanels {
    z-index: 503;
    border: 1px solid black;
    position: absolute;
    border: 0px;
    right: 10%;
    left: 10%;
    bottom: 3% !important;
    width: 50%;
  }
  .bufferPanelsBody {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
  }
}
@media (max-width: 700px) {
  .bufferPanels {
    z-index: 503;
    border: 1px solid black;
    position: absolute;
    border: 0px;
    right: 10%;
    left: 10%;
    bottom: 3% !important;
    width: 60%;
  }
  .bufferPanelsBody {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
  }
}
