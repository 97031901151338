.gotoPanels {
  z-index: 1;
  position: absolute;
  bottom: 59px;
  left: 63px;
  width: 253px;
  height: 163px;
}
@media (max-width: 992px) {
  .gotoPanels {
    z-index: 503;
    border: 1px solid black;
    position: absolute;
    border: 0px;
    right: 10%;
    left: 10%;
    bottom: 3% !important;
    width: 50%;
  }
  .gotoPanelsBody {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
  }
}
@media (max-width: 700px) {
  .gotoPanels {
    z-index: 503;
    border: 1px solid black;
    position: absolute;
    border: 0px;
    right: 10%;
    left: 10%;
    bottom: 3% !important;
    width: 60%;
  }
  .gotoPanelsBody {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
  }
}

.gotoPanelsButtons {
  width: 50%;
  margin-left: 25%;
  justify-content: center;
  display: flex;
}
