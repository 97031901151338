.widget-button {
  position: absolute;
  z-index: 1;
  border-radius: 27px !important;
  font-size: 2em;
  background-color: var(--white-color);
  margin-bottom: 5px;
  line-height: 1.4em;
  transition: background-color 0.3s;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
  outline: none;
  display: inline-block;
  padding: 0px;
  color: var(--primary-color);
  height: 40px;
  width: 40px;
  border: none;
}
.widget-button:hover img {
  -webkit-filter: invert(100%) brightness(599%);
}
.widget-button:hover {
  background-color: var(--primary-color);
}
