.measurePanels {
  z-index: 1;
  position: absolute;
  bottom: 34px;
  left: 66px;
  width: 253px;
  height: 163px;
}
.extendMeasureButton {
  top: 4em;
  left: 50%;
  border-radius: 20px !important;
  font-size: 2em;
  background-color: var(--white-color);
  margin-bottom: 5px;
  line-height: 1.4em;
  transition: background-color 0.3s;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
  outline: none;
  padding: 0px;
  height: 1.375em;
  width: 1.375em;
  border: none;
  color: red;
}

.measureClearButton {
  width: 50%;
  margin-left: 25%;
  justify-content: center;
  display: flex;
}
.extendMeasureButton:hover img {
  -webkit-filter: invert(100%) brightness(599%);
}
.extendMeasureButton:hover {
  background-color: var(--primary-color);
}

@media (max-width: 992px) {
  .measurePanels {
    z-index: 503;
    border: 1px solid black;
    position: absolute;
    border: 0px;
    right: 10%;
    left: 10%;
    bottom: 3% !important;
    width: 50%;
  }
  .measurePanelsBody {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
  }
}
@media (max-width: 700px) {
  .measurePanels {
    z-index: 503;
    border: 1px solid black;
    position: absolute;
    border: 0px;
    right: 10%;
    left: 10%;
    bottom: 3% !important;
    width: 60%;
  }
  .measurePanelsBody {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
  }
}

.cols {
  justify-content: center;
  display: flex;
}

.measureButtonContainer {
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
}
.measureButtons {
  float: left;
  width: 33%;
  justify-content: center;
  display: flex;
}
.measureResultContainer {
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  justify-content: center;
  display: flex;
}
.measureResult {
  justify-content: center;
  display: flex;
}

.btn-success {
  color: var(--white-color) !important;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}
.btn-primary {
  color: var(--white-color) !important;
  background-color: var(--secondary-color) !important;
  border-color: var(--white-color) !important;
}
.panel-default .panel-heading {
  border-radius: 15px 15px 0 0;
  background-color: var(--secondary-color) !important;
  color: var(--white-color) !important;
  border: none !important;
  margin-bottom: 10px;
}
.panel {
  border: none !important;
}
.panelheadbtn {
  background-color: var(--secondary-color) !important;
  color: var(--white-color) !important;
  border: none;
  margin-top: -14px !important;
}
.panelheadbtn:hover {
  cursor: pointer;
}
.panel-footer {
  border-radius: 0 0 15px 15px !important;
  background-color: white !important;
}
