.containerBaseMap {
  display: block;
  position: relative;
  padding-left: 29px;
  margin-top: 10px;
  margin-left: 17px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #2b2b2b;
}
/* Hide the browser's default checkbox */
.containerBaseMap input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 4px;
  height: 24px;
  width: 24px;
  border: 1px solid #c0c9db;
}

/* On mouse-over, add a grey background color */
.containerBaseMap:hover input ~ .checkmark {
  background-color: var(--secondary-color);
  border: 1px solid #c0c9db;
}

/* When the checkbox is checked, add a blue background */
.containerBaseMap input:checked ~ .checkmark {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.containerBaseMap input:checked ~ .checkmark:after {
  margin-top: -1px;
  margin-left: 2px;
  display: block;
  width: 8px;
  height: 15px;
}

/* Style the checkmark/indicator */
.containerBaseMap .checkmark:after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
hr {
  margin-top: 8px !important;
  margin-bottom: 1px !important;
  border: 0;
  border-top: 1px solid #eee;
}

.range {
  position: relative;
}
.range input[type="range"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 17px;
  width: 100%;
  height: 22px;
  cursor: pointer;
  display: block;
}
.range input[type="range"]:focus {
  outline: none;
}
.range input[type="range"][disabled] {
  opacity: 0.3;
  cursor: default;
}
.range .rangeslider {
  position: relative;
  height: 22px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.range .rangeslider:before {
  box-sizing: border-box;
  width: 100%;
  height: 4px;
  background: yellow;
  border-radius: 100px;
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.range input::-webkit-slider-runnable-track {
  box-sizing: border-box;
  width: 100%;
  height: 8px;
  background: var(--primary-color);
  border-radius: 100px;
  margin: 11px 0;
}
.range input::-moz-range-track {
  box-sizing: border-box;
  width: 100%;
  height: 4px;
  background: #e6e6e6;
  border-radius: 100px;
  margin: 11px 0;
}
.range input::-ms-track {
  box-sizing: border-box;
  width: 100%;
  height: 4px;
  background: var(--primary-color);
  border-radius: 100px;
  color: transparent;
  padding: 11px 0;
  background: transparent;
  border-color: transparent;
}
input[type="range"]::-ms-fill-lower,
input[type="range"]::-ms-fill-upper {
  box-sizing: border-box;
  width: 100%;
  height: 4px;
  background: #e6e6e6;
  border-radius: 100px;
}
input[type="range"]:focus::-ms-fill-lower {
  background: var(--primary-color);
}
.range .rangeslider-fill-lower {
  background-color: var(--primary-color);
  border-radius: 100px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 4px;
  will-change: width;
}
.range input::-webkit-slider-thumb {
  box-sizing: border-box;
  box-shadow: 0px 4px 15px #c0c9db;
  border: 6px solid white;
  height: 24px;
  width: 24px;
  border-radius: 100px;
  background: var(--primary-color);
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  transform: translateY(-50%);
  margin-top: 2px;
}
.range:active::-webkit-slider-thumb {
  background: #1abc9c;
}
.range input::-moz-range-thumb {
  box-sizing: border-box;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.5);
  border: 6px solid var(--secondary-color);
  height: 24px;
  width: 24px;
  border-radius: 100px;
  background: #333940;
  cursor: pointer;
}
.range input::-ms-thumb {
  box-sizing: border-box;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.5);
  border: 6px solid var(--secondary-color);
  height: 24px;
  width: 24px;
  border-radius: 100px;
  background: #333940;
  cursor: pointer;
}
