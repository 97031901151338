.reportContainer {
  /* background: url("./images/kerala5.jpg") no-repeat center center fixed; */
  background-size: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  overflow-y: auto;
}
.reportTitle {
  width: 100% !important;
  padding: 15px;
  font-size: 24px;
  background: #f9f9fe;
  text-align: left;
  margin-bottom: 10px;
  border-radius: 12px 12px 0px 0px;
  color: #2b2b2b;
}
.back {
  margin-left: 30px;
  margin-right: 20px;
  height: 20px;
  width: 20px;
  cursor: pointer;
}
.reportCard {
  position: absolute;
  width: 90%;
  right: 5%;
  left: 5%;
  /* height: 100%; */
  /* max-height: calc(100vh - 100px); */
  margin-top: 10px;
  background: #f9f9fe;
  border-radius: 12px;
  transition: all 0.2s ease-in-out;
}
.selectLayerRow {
  margin-top: 15px;
  padding: 10px;
}
.selectLayer {
  margin-left: 20px;
}
.addfilterbutton {
  padding: 3px;
  border-color: transparent !important;
  background-color: transparent !important;
  margin-top: 28px !important;
  color: var(--primary-color);
}
.addfilterbutton:disabled {
  cursor: not-allowed;
}
.btn_sel_col {
  margin: 24px 5px 0 20px;
  height: 33px;
  background-color: var(--white-color);
  border-color: var(--primary-color);
}
.btn_submit {
  margin: 24px 5px 0 20px;
  height: 33px;
}
.resultContainerModel {
  margin-top: 10px;
  max-height: 300px !important;
  overflow-y: auto;
  overflow-x: auto;
  margin-left: 20px;
}
.drp_layer {
  height: 34px;
}
.countBadge {
  padding: 5px 8px 5px 8px !important;
  margin-bottom: 1px !important;
  font-weight: 800;
  background-color: #72b872;
}
.excellButton {
  padding: 3px;
  font-size: 12px;
  line-height: 2;
  width: 100%;
  margin-top: 26px;
}
.excelNameReport {
  padding: 1px 14px;
  font-size: 14px;
  width: 100%;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.resultProgressBar {
  width: 100;
  justify-content: center;
  margin: auto;
}
.resultProgressBarDiv {
  width: 100%;
  margin-top: 5px;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 7%;
}
.noDataTxt {
  text-align: center;
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/
@media (min-width: 1281px) {
}
/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/
@media (min-width: 1025px) and (max-width: 1280px) {
}
/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) {
}
/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}
/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/
@media (min-width: 481px) and (max-width: 767px) {
  .btn_addfilter {
    margin: 10px 5px 0 5px;
  }
  .btn_sel_col {
    margin: 10px 5px 0 5px;
  }
  .btn_submit {
    margin: 10px 5px 0 5px;
  }
}
/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/
@media (min-width: 320px) and (max-width: 480px) {
  .btn_addfilter {
    margin: 10px 5px 0 5px;
  }
  .btn_sel_col {
    margin: 10px 5px 0 5px;
  }
  .btn_submit {
    margin: 10px 5px 0 5px;
  }
}
