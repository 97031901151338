#map {
  border: none;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}
.ol-zoom {
  color: var(--white-color);
  background: none !important;
  left: 13px;
  top: 104px;
}

.ol-zoom .ol-zoom-out,
.ol-zoom .ol-zoom-in {
  border-radius: 27px !important;
  font-size: 2em !important;
  background-color: var(--white-color);
  margin-bottom: 10px;
  color: var(--primary-color);
  line-height: 1.4em;
  transition: background-color 0.3s;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
  outline: none;
  height: 40px;
  width: 40px;
}

.ol-zoom .ol-zoom-out:hover,
.ol-zoom .ol-zoom-in:hover,
.ol-zoom .ol-zoom-out:focus,
.ol-zoom .ol-zoom-in:focus {
  outline-style: none;
  background-color: var(--primary-color);
  color: var(--white-color);
}

.map-info{
  
}

.ol-scale-line {
  bottom: 1.4em;
  right: 10.7em;
  z-index: 0;
  background: var(--primary-color);
  border-radius: 4px 0px 0px 4px;
  left: unset;
}

div.ol-mouse-position:not(:empty) {
  background: var(--primary-color);
  color: var(--white-color);
  border-radius: 0px 4px 4px 0px;
  position: absolute;
  font-size: 11px;
  padding: 3px;
}

.ol-mouse-position {
  background: var(--primary-color);
  position: absolute;
  right: 5.2em;
  z-index: 0;
  outline: none;
  display: block;
  color: var(--white-color);
  text-align: center;
  max-width: 107px;
  width: 93px;
  bottom: 1.8em;
  top: auto;
}

@media only screen and (max-width: 320px) {
  .ol-scale-line {
    bottom: 0.2em;
    right: 10.2em;
  }

  .ol-mouse-position {
    bottom: 0.4em;
    right: 4.7em;
  }
  div.ol-mouse-position:not(:empty) {
    padding: 2px;
  }
}
@media only screen and (max-width: 425px) {
  .ol-zoom {
    left: 2px;
  }
}
@media only screen and (max-width: 768px) {
  #map {
    border: none;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
  }
  .ol-zoom {
    top: 59px;
  }

  .extendButton {
    top: 5.8em;
  }
}

@media only screen and (max-width: 992px) {
  #map {
    border: none;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
  }
}
