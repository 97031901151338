.reportFilterModal {
  top: 20px;
}
.reportFilterPanel {
  margin-bottom: 0px;
}
.report {
  margin-left: 3px;
}

.filterFilterModal {
  top: 60px;
}
.filterFilterPanel {
  margin-bottom: 0px;
}

.filterFilterModal .modal-content{
  width: 300px !important;
}
.filterFilterModal .modal.in .modal-dialog{
  width: 300px !important;
}
