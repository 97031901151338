.detailedInfoPanel {
  z-index: 1;
  position: absolute;
  border: 1px solid black;
  border: 0px;
  left: 10px;
  margin-top: 12px;
  width: 300px;
  top: 100px;
}

.detailedInfoContent {
  font-size: 12px !important;
}

.infobutton {
  padding-top: 0.24em !important;
  padding-bottom: 0.16em !important;
}

.Count_details {
  height: 25px;
  margin-bottom: 5px;
  background-color: var(--grey);
}

.padding {
  padding-bottom: 5px;
  padding-top: 9px;
  margin-top: 15px;
}

.featureInfoPanelBody {
  max-height: calc(85vh - 79px);
  overflow-x: auto;
  overflow-y: auto;
  padding: 2px;
}

.featureInfoFooter {
  position: fixed;
  bottom: 0;
  width: 350px;
}

#sidebar {
  padding: 15px 10px;
  border: none;
  border-radius: 0;
  margin-bottom: 40px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.imageModel .modal-open .modal {
  overflow-y: hidden !important;
}

.imageModel {
  margin-top: 20px;
  border-radius: 10% 10% 10% 10%;
}

.imageModelHeader {
  color: white;
  background-color: var(--secondary-color);
  text-align: center;
}

.imageModelFooter {
  color: var(--white-color);
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}

.imageContainer img {
  max-height: 100%;
  max-width: 100%;
}

.imageContainerModel {
  max-height: 631px !important;
  overflow-y: auto;
}

.show-gridsss {
  width: 330px !important;
  margin-top: 5%;
}

.progresbar {
  padding-right: 0%;
  padding-left: 8%;
}

.progresbarname {
  padding-right: 0%;
  padding-left: 6%;
}

.dpanelheadbtn {
  background-color: var(--secondary-color) !important;
  color: var(--white-color) !important;
  border: none;
  margin-top: -19px !important;
  margin-left: 10px;
}

.pdfModel {
  margin-top: 30px;
  border-radius: 10% 10% 10% 10%;
}

.pdfModelHeader {
  color: var(--white-color);
  background-color: var(--secondary-color);
  text-align: center;
}

.pdfModelFooter {
  color: var(--white-color);
  border-color: var(--secondary-color);
}

.pdfContainer img {
  max-height: 100%;
  max-width: 100%;
}

.pdfContainerModel {
  padding-bottom: 56.25%;
  position: relative;
  display: block;
  width: 100%;
  height: 0;
}

.pdf_iframe {
  overflow-y: auto;
  padding: 2px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #d3d3dd #f2f5fb;
}

*::-webkit-scrollbar {
  width: 7px;
  height: 11px;
}

*::-webkit-scrollbar-track {
  background: #f2f5fb;
}

*::-webkit-scrollbar-thumb {
  background-color: #d3d3dd;
  border-radius: 20px;
  border: 1px solid #f2f5fb;
}

.pdfBotton {
  margin: 0 auto 10px auto;
  width: 56%;
}

.image-rotate {
  -ms-transform: rotate(90deg);
  /* IE 9 */
  transform: rotate(90deg);
}

.label-primary {
  background-color: var(--label) !important;
}

.photoheadbtn {
  background-color: var(--secondary-color) !important;
  color: var(--white-color) !important;
  border: none;
  margin-top: 4px !important;
}


/* Style for DetailInfoTable */
.detail-info-table {
  width: 100%;
  border-collapse: collapse;
}

.detail-info-table td {
  border: 1px solid #ddd;
  padding: 8px;
  width: 50%;
}

.detail-info-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.detail-info-table tr:hover {
  background-color: #ddd;
}

.detail-info-table .floor-area-header {
  font-size: 14px;
  background-color: #ca2208;
  text-align: center;
  font-weight: bolder;
  padding: 8px;
}

.detail-cell {
  font-size: 12px;
  width: 50%;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}