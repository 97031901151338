.nav-bar .navbar {
  z-index: 2 !important;
  position: absolute;
  width: 100%;
  background-color: var(--primary-color);
}
.nav-bar .navbar-default .navbar-brand {
  color: white;
}
.nav-bar .navbar-default .navbar-nav > li > a {
  color: white;
}
.left-right-svg-div {
  text-align: center;
}
.left-right-svg-div svg {
  margin-top: 25px;
  color: green;
}
.title {
  margin-left: 21px;
  margin-top: 18px;
  margin-bottom: 21px;
  font-size: 23px;
}
.view-search-button {
  box-shadow: 0 1px 4px rgb(0 0 0 / 60%);
  width: 25px;
  z-index: 1 !important;
  position: absolute;
  top: 18px;
  left: 0px;
  /* height: 75px; */
  height: 66px;
  border-radius: 0px 6px 6px 0px;
  padding: 0px !important;
  background-color: white;
  color: white;
  margin-bottom: 0px !important;
}
.jumboBox {
  /* width: 622px; */
  width: 750px;
  z-index: 1 !important;
  position: absolute;
  top: 18px;
  left: 20px;
  /* height: 75px; */
  height: 66px;
  border-radius: 6px;
  padding: 0px !important;
  background-color: var(--primary-color);
  color: white;
  margin-bottom: 0px !important;
}
.toolbarResponsive {
  width: max-content;
  margin: auto;
}
.collapseResponsive {
  height: 127px !important;
}
@media only screen and (min-width: 769px) {
  .toolIcons {
    z-index: 1;
  }
}

@media only screen and (max-width: 992px) {
  .jumboBox {
    width: 400px;
  }
}
@media only screen and (max-width: 768px) {
  .jumboBox {
    width: 100%;
    top: 0px;
    left: 0px;
    /* display: none; */
  }
}
@media only screen and (min-width: 768px) {
  .navbar-collapse.collapse {
    height: 90px !important;
  }
  /* .navbar-nav {
    float: none !important;
} */
}

.toolBarButtons {
  position: absolute;
  z-index: 1;
  border-radius: 27px !important;
  font-size: 2em;
  background-color: var(--white-color);
  outline: none;
  display: inline-block;
  padding: 0px;
  height: 40px;
  width: 40px;
}
.toolIcons:hover img {
  -webkit-filter: invert(100%) brightness(599%);
}
.toolIcons:hover {
  background-color: var(--primary-color) !important;
}
.toolIcons {
  font-size: 12px !important;
  background-size: cover;
  /* z-index: 1; */
  position: absolute;
  background-color: white !important;
  border-radius: 27px !important;
  width: 40px;
  height: 40px;
  border: none;
  outline: none;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
}
.navIcons {
  width: 35px;
  height: 35px;
  font-size: 12px !important;
  background-size: cover;
  position: absolute;
  z-index: 1;
  margin-left: 20px;
  /* top: 8px; */
  background-color: #9c1804 !important;
  border-radius: 20px !important;
}

.black {
  color: black;
}

.navbar-semi .navbar-nav.social-links li a {
  border-width: 0;
  padding-left: 3px;
  padding-right: 3px;
}

.appHeader {
  margin-bottom: 0px;
  border-radius: 0px;
  background: var(--blue);
  height: 50px;
  border: 0px;
}
.appNavBar,
.appNavBar a {
  height: 40px;
}

.appNavBar li a {
  padding: 15px 5px;
}

.input-group-addon,
.input-group-btn {
  vertical-align: top;
}

.navbar-nav {
  margin: 0;
}

.navbar-inverse .navbar-collapse,
.navbar-inverse .navbar-form {
  border: 0;
}

.navbar-semi {
  /* min-height: 40px; */
  background: "#7C4579";
}

.navbar-semi.navbar-fixed-top,
.navbar-semi.navbar-static-top {
  border-radius: 0;
}

.navbar-semi .navbar-toggle {
  border-color: var(--white-color);
  padding: 0;
  margin-top: 11px;
}

.navbar-semi .navbar-toggle .icon-bar {
  background-color: var(--white-color);
  height: 3px;
}

.navbar-semi .navbar-brand {
  font-weight: bold;
  color: var(--white-color);
  height: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.navbar-semi .navbar-nav > li:nth-child(1) {
  border-left: 0px solid var(--white-color);
}

.navbar-semi .navbar-nav > li > a {
  padding-top: 5px;
  padding-bottom: 5px;
  border-right: 0px solid #000;
}

@media (max-width: 768px) {
  .navbar-semi .navbar-nav > li > a {
    box-shadow: inset 0 0 0 rgba(255, 255, 255, 0.53);
    -webkit-box-shadow: inset 0 0 0 rgba(255, 255, 255, 0.53);
  }
}

.navbar-semi .navbar-nav > li > a:hover,
.navbar-semi .navbar-nav > li > a:focus {
  background-color: rgb(19, 121, 236);
}

@media only screen and (max-width: 768px) {
  .navbar-semi .navbar-nav.social-links {
    text-align: center;
  }
  .navbar-semi .navbar-nav.social-links li {
    display: inline-block;
    border-width: 0;
    padding-left: 5px;
    padding-right: 5px;
  }
  .navbar-semi .navbar-nav.social-links li a {
    border-width: 0;
  }
}

.navbar-semi .nav .open > a:focus {
  border-width: 0;
}

.navbar-semi .dropdown-menu {
  left: -2px;
  background-color: #101010;
  cursor: pointer;
}

.navbar-semi .dropdown-menu > li > a {
  padding: 7px 18px;
  color: var(--white-color);
  -webkit-transition: all 300ms;
  -o-transition: all 300ms;
  transition: all 300ms;
  cursor: pointer;
}

.navbar-semi .dropdown-menu > li > a:hover,
.navbar-semi .dropdown-menu > li > a:focus {
  background-color: #1d75bc;
  -webkit-box-shadow: inset 5px 0 0 var(--white-color);
  box-shadow: inset 5px 0 0 var(--white-color);
  cursor: pointer;
}

.navbar-semi .navbar-form {
  padding: 0px;
  margin: 3px -28px 0 3px;
}

.navbar-semi .navbar-form .form-control {
  width: 200px;
  background-color: #101010;
  border-color: #2a2a2a;
  -webkit-transition: width 300ms;
  -o-transition: width 300ms;
  transition: width 300ms;
  height: 28px;
  top: 2px;
}

.navbar-semi .navbar-form .form-control:focus {
  /*  width: 250px; */
  -webkit-box-shadow: 0 0 0, 0 0 0;
  box-shadow: 0 0 0, 0 0 0;
}

@media (max-width: 768px) {
  .navbar-semi .navbar-form {
    margin: 0;
  }
  .navbar-semi .navbar-form .form-control {
    width: 100%;
  }
  .navbar-semi .navbar-form .form-control:focus {
    width: 100%;
  }
}

.navbar-semi .navbar-form .form-control {
  background-color: var(--white-color);
  border-color: var(--white-color);
}

@media (max-width: 400px) {
  .navbar-semi .navbar-form {
    margin: 0;
  }
  .navbar-semi .navbar-form .form-control {
    width: 100%;
  }
  .navbar-semi .navbar-form .form-control:focus {
    width: 100%;
  }

  .navbar-semi .navbar-nav.social-links {
    text-align: center;
  }
  .navbar-semi .navbar-nav.social-links li {
    display: inline-block;
    border-width: 0;
    width: 40px;
  }
  .navbar-semi .navbar-nav.social-links li a {
    border-width: 0;
    width: 40px;
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (max-width: 992px) {
  .navbar-header {
    float: none;
  }
  .navbar-left,
  .navbar-right {
    float: none !important;
  }
  .navbar-toggle {
    display: block;
  }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  }

  .navbar-collapse.collapse {
    display: none !important;
  }

  .navbar-nav > li {
    float: none;
  }
  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .collapse.in {
    display: block !important;
  }

  .navbar-semi .navbar-nav.social-links {
    text-align: center;
  }
  .navbar-semi .navbar-nav.social-links li {
    display: inline-block;
    border-width: 0;
    padding-left: 10px;
    padding-right: 10px;
  }
  .navbar-semi .navbar-nav.social-links li a {
    border-width: 0;
    padding-top: 3px;
    padding-bottom: 2px;
  }

  .navbar-semi .navbar-form {
    margin: 0;
  }
  .navbar-semi .navbar-form .form-control {
    width: 100%;
  }
  .navbar-semi .navbar-form .form-control:focus {
    width: 100%;
  }
}
.black {
  color: black;
}
.navBarForm {
  background-color: white;
  border-color: white;
  border-radius: 0px 5px 5px 0px;
}
#navBarFormButton {
  display: inline-block;
  padding: 6px 12px;
  margin-top: 2px;
  font-size: 11px;
  height: 28px;
  font-weight: 400;
  /* line-height: 1.42857143; */
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  /* cursor: pointer; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  /* user-select: none; */
  /* background-image: none; */
}

.form-control {
  display: block;
  width: 100%;
  top: 3px;
  height: 27px;
  padding: 1px 14px;
  font-size: 12px;
  line-height: 1.42857143;
  color: #555;
  background-color: var(--white-color);
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.rbt-input-hint {
  display: none;
}
.rbt-aux .rbt-close {
  margin-top: 5px !important;
}
.rbt .rbt-input-hint-container {
  height: 35px;
}
.input-group > .rbt:not(:last-child) .form-control {
  height: auto;
  border: none;
}

.search {
  position: absolute;
  bottom: 6.6em;
  left: 34px;
  z-index: 1;
  border-radius: 27px !important;
  font-size: 2em;
  background-color: var(--white-color);
  margin-bottom: 376px;
  line-height: 1.4em;
  transition: background-color 0.3s;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
  outline: none;
  display: inline-block;
  padding: 0px;
  color: var(--primary-color);
  height: 40px;
  width: 40px;
  border: none;
}
.search:hover img {
  -webkit-filter: invert(100%) brightness(599%);
}
.search:hover {
  background-color: var(--primary-color);
}
