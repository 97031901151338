.radio {
  color: #555;
  font-size: 15px;
  position: relative;
}
.radio span {
  position: relative;
  padding-left: 20px;
}
.radio span:after {
  content: "";
  width: 15px;
  height: 15px;
  border: 3px solid;
  position: absolute;
  left: 0;
  top: 1px;
  border-radius: 100%;
  -ms-border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  box-sizing: border-box;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.radio input[type="radio"] {
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}
.radio input[type="radio"]:checked + span {
  color: #0b8;
}
.radio input[type="radio"]:checked + span:before {
  content: "";
  width: 5px;
  height: 5px;
  position: absolute;
  background: #0b8;
  left: 5px;
  top: 6px;
  border-radius: 100%;
  -ms-border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
}
