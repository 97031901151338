.upload-widget-button {
    /* bottom: 2.5em;
    right: 0.5em; */
    /* position: relative; */
    position: absolute;
    bottom: 8em;
    right: 4em;
}

.popup-options {
    position: absolute;
    bottom: 3.5em;
    right: 4.5em;
    background-color: #ca2208;
    border: 1px solid #ca2208;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    padding: 10px;
    z-index: 10;
}

.popup-option {
    display: block;
    margin: 5px 0;
    padding: 8px 12px;
    background-color: white;
    color: #ca2208;
    border: 1px solid #000000;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.popup-option:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.popup-option:not(:disabled):hover {
    background-color: #ca2208;
    color: white;
    
}

@media only screen and (max-width: 425px) {
    .upload-widget-button {
        right: 5px;
    }

    .popup-options {
        right: 5px;
    }
}
