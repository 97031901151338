.rct-node-icon {
  padding: 10px;
  display: none;
}
.rct-title {
  font-size: 14px;
}
.rct-text {
  color: #2b2b2b;
  border: 1px solid #c0c5ce;
  padding: 8px;
  width: 100%;
  background: var(--white-color);
  border-right-color: var(--white-color);
  border-left-color: var(--white-color);
  border-top-color: var(--white-color);
}
.rct-text:hover {
  background: #ecf0f6;
}
label {
  font-weight: 10;
}
.rct-node .rct-node-leaf .rct-text {
  border: 0px solid #eaeaea !important;
  background: #ecf0f6;
  height: 38px;
  padding-top: 3px;
  font-style: none;
  color: #2b2b2b !important;
}

.rct-node .rct-node-leaf .rct-text .rct-collapse {
  margin-left: 15px;
}
.rct-node-leaf .rct-text:hover {
  background: #ecf0f6;
}
.react-checkbox-tree label:hover {
  background: none !important;
}
.rct-node {
  background: var(--secondary-color);
}
.rct-node .rct-node-leaf .rct-node-icon {
  display: none;
}

.rct-icon-expand-open::before {
  content: "\f056" !important;
  font: normal normal normal 20px/1 FontAwesome;
  color: #aab3c6;
}
.rct-icon-expand-close::before {
  content: "\f055" !important;
  font: normal normal normal 20px/1 FontAwesome;
  color: #aab3c6;
  border-color: #aab3c6;
}

.rct-collapse > .rct-icon-expand-close {
  opacity: 1;
}

.react-checkbox-tree ol ol {
  padding-left: 0px;
  background-color: none;
}
