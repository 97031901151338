@import url(https://fonts.googleapis.com/css2?family=Inter:wght@500&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  padding: 0;
}
:root {
  --primary-color: #ca2208;
  --secondary-color: #142240;
  --white-color: #fff;
  --blue: #3173ad;
  --green: #4ea64e;
  /* Layer Category */
  --layercategorytext: #282726;
  --hoverbg: #e3a09b;
  --plusopen: #282726;
  /* --white-color: #d8e0eb; */
  /* Scrollbar */
  --scroll: #d8e0eb;
}

.iconStyle {
  width: 25px;
  height: 25px;
}

.load-bar {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 8px;
  background-color: var(--primary-color);
  top: 0%;
}
.bar {
  content: "";
  display: inline;
  position: absolute;
  width: 0;
  height: 100%;
  left: 50%;
  text-align: center;
}
.bar:nth-child(1) {
  background-color: var(--secondary-color);
  -webkit-animation: loading 3s linear infinite;
          animation: loading 3s linear infinite;
}
.bar:nth-child(2) {
  background-color: #c0c9db;
  -webkit-animation: loading 3s linear 1s infinite;
          animation: loading 3s linear 1s infinite;
}
.bar:nth-child(3) {
  background-color: var(--primary-color);
  -webkit-animation: loading 3s linear 2s infinite;
          animation: loading 3s linear 2s infinite;
}
@-webkit-keyframes loading {
  from {
    left: 50%;
    width: 0;
    z-index: 100;
  }
  33.3333% {
    left: 0;
    width: 100%;
    z-index: 10;
  }
  to {
    left: 0;
    width: 100%;
  }
}
@keyframes loading {
  from {
    left: 50%;
    width: 0;
    z-index: 100;
  }
  33.3333% {
    left: 0;
    width: 100%;
    z-index: 10;
  }
  to {
    left: 0;
    width: 100%;
  }
}

.modelContainer {
  margin-top: 15%;
}
.fPassword {
  width: 100%;
  font-size: 2em;
  background: var(--secondary-color);
  text-align: center;
  margin-bottom: 20px;
  border-radius: 14px 14px 0 0;
  color: var(--white-color);
}
.fpanelheadbtn {
  /* background-color: var(--white-color) !important; */
  color: var(--white-color) !important;
  border: none;
  margin-top: -18px !important;
}
.fpanelheadbtn:hover {
  cursor: pointer;
}

@media only screen and (max-width: 480px) {
  .modelContainer {
    margin-top: 30%;
  }
}
@media only screen and (max-width: 380px) {
  .modelContainer {
    margin-top: 40%;
  }
}

#snackbar {
  visibility: visible;
  min-width: 250px;
  margin-left: -125px;
  background-color: #34495e;
  color: var(--white-color);
  text-align: center;
  padding: 10px 15px;
  position: fixed;
  left: 50%;
  bottom: 10px;
  font-size: 17px;
  border-radius: 3px;
  font-family: "Jaldi", sans-serif;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 4s;
  animation: fadein 0.5s, fadeout 0.5s 4s;
  transition: all 1s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  z-index: 1000;
}

@-webkit-keyframes fadein {
  from {
    bottom: 10px;
    opacity: 0;
  }
  to {
    bottom: 10px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 10px;
    opacity: 0;
  }
  to {
    bottom: 10px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 10px;
    opacity: 1;
  }
  to {
    bottom: 10px;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 10px;
    opacity: 1;
  }
  to {
    bottom: 10px;
    opacity: 0;
  }
}

.loginContainer {
  background: url(/static/media/loginBg.d2d4bc4f.jpg) no-repeat center center
    fixed;
  background-size: cover;
  height: 100%;
  width: 100%;
  position: absolute;
}
.loginText {
  border-radius: 2.5px !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border: none;
  border: 1px solid #b8b5b5;
  background: rgba(173, 173, 173, 0.99);
  outline: none;
}
.loginButton {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  transition: box-shadow 0.2s;
  outline: none;
  border-radius: 4px;
  display: inline-block;
  box-sizing: border-box;
  background-color: var(--primary-color) !important;
  color: var(--white-color) !important;
}
.loginContainer button[disabled] {
  background-color: var(--primary-color) !important;
  opacity: 1;
}
.captcha {
  margin: 5px auto;
  align-items: center;
  display: table;
}
.appTitle {
  width: 100%;
  font-size: 2.5em;
  margin-top: 10px;
  /* background: #1d75bc; */
  background: rgba(252, 252, 252, 0.99);
  text-align: center;
  margin-bottom: 10px;
  border-radius: 12px 12px 0px 0px;
  /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); */
  color: var(--primary-color);
}
.withoutappTitle {
  width: 100%;
  font-size: 2.5em;
  margin-top: 10px;
  background: transparent;
  text-align: center;
  margin-bottom: 10px;
  border-radius: 12px 12px 0px 0px;
  color: var(--white-color);
}
.withoutLogin {
  position: absolute;
  bottom: 20%;
  width: 90%;
  right: 5%;
  left: 5%;
  height: 300px;
  margin: auto;
  background: transparent;
  border-radius: 12px;
  transition: all 0.2s ease-in-out;
}
.login {
  position: absolute;
  bottom: 20%;
  width: 90%;
  right: 5%;
  left: 5%;
  height: 320px;
  margin: auto;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  background: rgba(252, 252, 252, 0.99);
  border-radius: 12px;
  transition: all 0.2s ease-in-out;
}
.loginLabel {
  font-weight: bold;
  color: #1d75bc;
}

/* @media only screen and (min-width: 320px) {
  .login {
    right: 20%;
    left: 20%;
    width: 80%;
    border-radius: 12px;
    bottom: 27%;
  }
  .loginmain {
    right: 20%;
    left: 20%;
    width: 40%;
    border-radius: 12px;
    bottom: 27%;
  }
  .loginFormTwo {
    margin-left: 25px;
    margin-right: 25px;
  }
} */

@media only screen and (min-width: 480px) {
  .login {
    width: 40%;
    border-radius: 12px;
    bottom: 27%;
  }
  .loginmain {
    right: 20%;
    left: 20%;
    width: 40%;
    border-radius: 12px;
    bottom: 27%;
  }
  .login form {
    margin: 0 auto;
    max-width: 320px;
  }
}

/* @media only screen and (min-width: 768px) {
  .login {
    width: 52%;
    border-radius: 12px;
    bottom: 27%;
  }
} */

@media only screen and (min-width: 1080px) {
  .login {
    /* right: 30%;
    left: 30%; */
    width: 30%;
    border-radius: 12px;
    bottom: 27%;
  }
  .login form {
    margin: 0 auto;
    max-width: 320px;
  }
}
@media only screen and (min-width: 1400px) {
  .login {
    right: 30%;
    left: 30%;
    width: 25%;
    border-radius: 12px;
    bottom: 27%;
  }

  .login form {
    margin: 0 auto;
    max-width: 320px;
  }
}

#map {
  border: none;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}
.ol-zoom {
  color: var(--white-color);
  background: none !important;
  left: 13px;
  top: 104px;
}

.ol-zoom .ol-zoom-out,
.ol-zoom .ol-zoom-in {
  border-radius: 27px !important;
  font-size: 2em !important;
  background-color: var(--white-color);
  margin-bottom: 10px;
  color: var(--primary-color);
  line-height: 1.4em;
  transition: background-color 0.3s;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
  outline: none;
  height: 40px;
  width: 40px;
}

.ol-zoom .ol-zoom-out:hover,
.ol-zoom .ol-zoom-in:hover,
.ol-zoom .ol-zoom-out:focus,
.ol-zoom .ol-zoom-in:focus {
  outline-style: none;
  background-color: var(--primary-color);
  color: var(--white-color);
}

.map-info{
  
}

.ol-scale-line {
  bottom: 1.4em;
  right: 10.7em;
  z-index: 0;
  background: var(--primary-color);
  border-radius: 4px 0px 0px 4px;
  left: unset;
}

div.ol-mouse-position:not(:empty) {
  background: var(--primary-color);
  color: var(--white-color);
  border-radius: 0px 4px 4px 0px;
  position: absolute;
  font-size: 11px;
  padding: 3px;
}

.ol-mouse-position {
  background: var(--primary-color);
  position: absolute;
  right: 5.2em;
  z-index: 0;
  outline: none;
  display: block;
  color: var(--white-color);
  text-align: center;
  max-width: 107px;
  width: 93px;
  bottom: 1.8em;
  top: auto;
}

@media only screen and (max-width: 320px) {
  .ol-scale-line {
    bottom: 0.2em;
    right: 10.2em;
  }

  .ol-mouse-position {
    bottom: 0.4em;
    right: 4.7em;
  }
  div.ol-mouse-position:not(:empty) {
    padding: 2px;
  }
}
@media only screen and (max-width: 425px) {
  .ol-zoom {
    left: 2px;
  }
}
@media only screen and (max-width: 768px) {
  #map {
    border: none;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
  }
  .ol-zoom {
    top: 59px;
  }

  .extendButton {
    top: 5.8em;
  }
}

@media only screen and (max-width: 992px) {
  #map {
    border: none;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
  }
}

.overlayPanel {
  z-index: 1;
  position: absolute;
  border-color: white !important;
  right: 20px;
  margin: auto;
  width: 300px;
  margin-top: 95px;
  border-radius: 20px;
}
.overlayPanelBody {
  max-height: calc(80vh - 80px);
  overflow-y: auto;
  padding: 0px;
}

@media (max-width: 992px) {
  .overlayPanel {
    z-index: 1;
    border: 1px solid black;
    position: absolute;
    border: 0px;
    right: 10%;
    left: 10%;
    bottom: 0% !important;
    width: 80%;
  }
  .overlayPanelBody {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
  }
}

.panel-heading button {
  margin-top: -26px;
  font-size: 15px;
}

/* 
::-webkit-scrollbar {
  width: 15px;
}


::-webkit-scrollbar-track {
  border-radius: 25px;
}


::-webkit-scrollbar-thumb {
  background-color: var(--scroll);
  border-radius: 25px;
}


::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar);
} */

* {
  scrollbar-width: thin;
  scrollbar-color: #d3d3dd #f2f5fb;
}
*::-webkit-scrollbar {
  width: 7px;
}
*::-webkit-scrollbar-track {
  background: #f2f5fb;
}

*::-webkit-scrollbar-thumb {
  background-color: #d3d3dd;
  border-radius: 20px;
  border: 1px solid #f2f5fb;
}

.btn-success {
  color: var(--white-color) !important;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}
.btn-primary {
  color: var(--white-color) !important;
  background-color: var(--secondary-color) !important;
  border-color: var(--white-color) !important;
}
.panel-default .panel-heading {
  border-radius: 15px 15px 0 0;
  background-color: var(--secondary-color) !important;
  color: var(--white-color) !important;
  border: none !important;
  margin-bottom: 0 !important;
}
.panel {
  border-radius: 15px 15px 15px 15px !important;
  border: none !important;
  min-height: 100px !important;
  /* box-shadow: 0px 0px 5px #00000047; */
}
.panelheadbtn {
  background-color: var(--secondary-color) !important;
  color: var(--white-color) !important;
  border: none;
  margin-top: -14px !important;
}
.panelheadbtn:hover {
  cursor: pointer;
}
.uncheck_all {
  margin-left: 82px !important;
  margin-right: 20px !important;
  margin-top: -23px !important;
  font-size: 12px !important;
  padding: 5px 3px 5px 3px !important;
  width: 105px;
  color: var(--primary-color);
  border-radius: 6px;
}
.cancel {
  color: var(--white-color);
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
  width: 14px;
  height: 14px;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 7px;
  border-radius: 5px;
  background: #3173ad;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
}
.layerName {
  margin-top: 8px;
  margin-bottom: -13px;
  margin-left: 18px;
}
.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #4ea64e;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #4caf50;
  cursor: pointer;
}

.rct-node-icon {
  padding: 10px;
  display: none;
}
.rct-title {
  font-size: 14px;
}
.rct-text {
  color: #2b2b2b;
  border: 1px solid #c0c5ce;
  padding: 8px;
  width: 100%;
  background: var(--white-color);
  border-right-color: var(--white-color);
  border-left-color: var(--white-color);
  border-top-color: var(--white-color);
}
.rct-text:hover {
  background: #ecf0f6;
}
label {
  font-weight: 10;
}
.rct-node .rct-node-leaf .rct-text {
  border: 0px solid #eaeaea !important;
  background: #ecf0f6;
  height: 38px;
  padding-top: 3px;
  font-style: none;
  color: #2b2b2b !important;
}

.rct-node .rct-node-leaf .rct-text .rct-collapse {
  margin-left: 15px;
}
.rct-node-leaf .rct-text:hover {
  background: #ecf0f6;
}
.react-checkbox-tree label:hover {
  background: none !important;
}
.rct-node {
  background: var(--secondary-color);
}
.rct-node .rct-node-leaf .rct-node-icon {
  display: none;
}

.rct-icon-expand-open::before {
  content: "\f056" !important;
  font: normal normal normal 20px/1 FontAwesome;
  color: #aab3c6;
}
.rct-icon-expand-close::before {
  content: "\f055" !important;
  font: normal normal normal 20px/1 FontAwesome;
  color: #aab3c6;
  border-color: #aab3c6;
}

.rct-collapse > .rct-icon-expand-close {
  opacity: 1;
}

.react-checkbox-tree ol ol {
  padding-left: 0px;
  background-color: none;
}

.radio {
  color: #555;
  font-size: 15px;
  position: relative;
}
.radio span {
  position: relative;
  padding-left: 20px;
}
.radio span:after {
  content: "";
  width: 15px;
  height: 15px;
  border: 3px solid;
  position: absolute;
  left: 0;
  top: 1px;
  border-radius: 100%;
  -ms-border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  box-sizing: border-box;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.radio input[type="radio"] {
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}
.radio input[type="radio"]:checked + span {
  color: #0b8;
}
.radio input[type="radio"]:checked + span:before {
  content: "";
  width: 5px;
  height: 5px;
  position: absolute;
  background: #0b8;
  left: 5px;
  top: 6px;
  border-radius: 100%;
  -ms-border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
}

.containerBaseMap {
  display: block;
  position: relative;
  padding-left: 29px;
  margin-top: 10px;
  margin-left: 17px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  user-select: none;
  color: #2b2b2b;
}
/* Hide the browser's default checkbox */
.containerBaseMap input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 4px;
  height: 24px;
  width: 24px;
  border: 1px solid #c0c9db;
}

/* On mouse-over, add a grey background color */
.containerBaseMap:hover input ~ .checkmark {
  background-color: var(--secondary-color);
  border: 1px solid #c0c9db;
}

/* When the checkbox is checked, add a blue background */
.containerBaseMap input:checked ~ .checkmark {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.containerBaseMap input:checked ~ .checkmark:after {
  margin-top: -1px;
  margin-left: 2px;
  display: block;
  width: 8px;
  height: 15px;
}

/* Style the checkmark/indicator */
.containerBaseMap .checkmark:after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
hr {
  margin-top: 8px !important;
  margin-bottom: 1px !important;
  border: 0;
  border-top: 1px solid #eee;
}

.range {
  position: relative;
}
.range input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  padding: 17px;
  width: 100%;
  height: 22px;
  cursor: pointer;
  display: block;
}
.range input[type="range"]:focus {
  outline: none;
}
.range input[type="range"][disabled] {
  opacity: 0.3;
  cursor: default;
}
.range .rangeslider {
  position: relative;
  height: 22px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}
.range .rangeslider:before {
  box-sizing: border-box;
  width: 100%;
  height: 4px;
  background: yellow;
  border-radius: 100px;
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.range input::-webkit-slider-runnable-track {
  box-sizing: border-box;
  width: 100%;
  height: 8px;
  background: var(--primary-color);
  border-radius: 100px;
  margin: 11px 0;
}
.range input::-moz-range-track {
  box-sizing: border-box;
  width: 100%;
  height: 4px;
  background: #e6e6e6;
  border-radius: 100px;
  margin: 11px 0;
}
.range input::-ms-track {
  box-sizing: border-box;
  width: 100%;
  height: 4px;
  background: var(--primary-color);
  border-radius: 100px;
  color: transparent;
  padding: 11px 0;
  background: transparent;
  border-color: transparent;
}
input[type="range"]::-ms-fill-lower,
input[type="range"]::-ms-fill-upper {
  box-sizing: border-box;
  width: 100%;
  height: 4px;
  background: #e6e6e6;
  border-radius: 100px;
}
input[type="range"]:focus::-ms-fill-lower {
  background: var(--primary-color);
}
.range .rangeslider-fill-lower {
  background-color: var(--primary-color);
  border-radius: 100px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  height: 4px;
  will-change: width;
}
.range input::-webkit-slider-thumb {
  box-sizing: border-box;
  box-shadow: 0px 4px 15px #c0c9db;
  border: 6px solid white;
  height: 24px;
  width: 24px;
  border-radius: 100px;
  background: var(--primary-color);
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  margin-top: 2px;
}
.range:active::-webkit-slider-thumb {
  background: #1abc9c;
}
.range input::-moz-range-thumb {
  box-sizing: border-box;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.5);
  border: 6px solid var(--secondary-color);
  height: 24px;
  width: 24px;
  border-radius: 100px;
  background: #333940;
  cursor: pointer;
}
.range input::-ms-thumb {
  box-sizing: border-box;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.5);
  border: 6px solid var(--secondary-color);
  height: 24px;
  width: 24px;
  border-radius: 100px;
  background: #333940;
  cursor: pointer;
}


.resultModal {
  position: absolute !important;
  z-index: 1000 !important;
  top: 162px;
  left: 470px;
  width: 350 !important;
  border-radius: 15px;
  box-shadow: none !important;
  border: none !important;
}
.resultModal .modal.in .modal-dialog {
  width: 350px !important;
  border-radius: 15px !important;
}
.resultModal .modal-content {
  border-radius: 18px !important;
  width: 350px !important;
  align-content: center;
}
.resultModalPanel {
  margin-bottom: 0px;
  width: 350;
}
@media only screen and (max-width: 768px) {
  .resultModal {
    top: 150px;
    left: 0px;
    overflow-x: scroll;
    width: 327px !important;
  }
  .resultModal .modal.in .modal-dialog {
    width: 312px !important;
  }
  .resultModal .modal-content {
    width: 312px !important;
  }
  .advancedFilterPanel {
    width: 312px !important;
  }
}

.panelBody {
  max-height: calc(70vh - 170px);
  min-height: 60px;
  overflow-y: auto;
  border-radius: 15px !important;
}
/* width */
::-webkit-scrollbar {
  width: 12px;
}
.report {
  margin-left: 3px;
}
.addfilterbtn {
  padding: 3px;
  border-color: var(--secondary-color) !important;
  background-color: var(--secondary-color) !important;
  margin-left: 15px !important;
  color: var(--primary-color);
}

.panelBody {
  max-height: calc(70vh - 170px);
  min-height: 60px;
  overflow-y: auto;
  border-radius: 15px !important;
}
/* width */
::-webkit-scrollbar {
  width: 12px;
}
.resultbutton {
  display: block;
  margin: auto;
}

.widget-button {
  position: absolute;
  z-index: 1;
  border-radius: 27px !important;
  font-size: 2em;
  background-color: var(--white-color);
  margin-bottom: 5px;
  line-height: 1.4em;
  transition: background-color 0.3s;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
  outline: none;
  display: inline-block;
  padding: 0px;
  color: var(--primary-color);
  height: 40px;
  width: 40px;
  border: none;
}
.widget-button:hover img {
  -webkit-filter: invert(100%) brightness(599%);
}
.widget-button:hover {
  background-color: var(--primary-color);
}

.selectButton {
  bottom: 0.3em;
  left: 17px;
}
@media only screen and (max-width: 425px) {
  .selectButton {
    left: 5px;
  }
}

.resultModal{
    position: absolute;
    z-index: 1;
    cursor: pointer;
}

.save-model-body{
    display: flex !important;
}


/* polygonTable */

.polygonTable{

}

.polygonTable .modal-dialog{
    position: absolute;
    right: 2%;
    width: 22%;
}

.polygonTablePanel{
    margin: 0px !important;
    position: absolute;
    z-index: 1;
    top: 15%;
    right: 3%;
}

.polygonTableRow{
    border: 1px solid #808080a1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-inline: 1px;
    border-radius: 4px;
    padding: 2px 0px 3px 0px;
}

.polygonTableHeading{
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-variation-settings: "slnt" 0;
    font-size: 14px;
}

.polygonTableIcon{
    cursor: pointer;
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    font-size: 17px;
    justify-content: flex-end;
}

.polygonTableButton{
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.polygonTableButton Button{
    background-color: #ef9385 !important;
    color: var(--white-color) !important;
    outline: none !important;
}

.polygonTableButton Button:hover{
    background-color: var(--primary-color) !important;
    color: var(--white-color) !important;
}



@media screen and (max-width: 900px) {
   .polygonTable .modal-dialog{
    position: absolute;
    right: 2%;
    width: 31%;
    }
    
}

@media screen and (max-width: 700px) {
    .polygonTable .modal-dialog{
     position: absolute;
     right: 2%;
     width: 43%;
     }
     
 }
.extendButton {
  top: 7.4em;
  left: 17px;
}
@media only screen and (max-width: 425px) {
  .extendButton {
    left: 5px;
  }
}

.ol-popup {
  position: absolute;
  z-index: 2;
  background-color: white;
  -webkit-filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  padding: 0px;
  border-radius: 15px;
  bottom: 12px;
  left: -50px;
  min-width: 280px;
}
.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}
.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}
.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
}
.ol-popup-closer:after {
  content: "✖";
}

.more_info {
  cursor: pointer;
}
.minimalInfoPopup {
  margin-bottom: 15px;
  width: 325px;
}
.minimalInfoPanelBody {
  padding: 10px;
  border: none !important;
  border-radius: none !important;
  max-height: calc(50vh - 79px);
  overflow-x: auto;
  overflow-y: auto;
}
.minimalInfoPanelBody .panel-body {
  border: none !important;
  border-radius: 0px !important;
}
.minimalinfoContent {
  margin-bottom: 3px;
  font-size: 12px !important;
  line-height: 20px;
}
.popupCloseButton {
  margin-top: -18px !important;
  margin-right: 0px !important;
  font-size: 12px !important;
  padding: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.minimalInfoHead {
  padding: 8px !important;
}
.mpanelheadbtn {
  background-color: var(--secondary-color) !important;
  color: var(--white-color) !important;
  border: none;
  margin-top: -14px !important;
  margin-left: 10px;
}
.mpanelheadbtn:hover {
  cursor: pointer;
}
.Btn_toolbar {
  margin-bottom: 3px !important;
}
.countbuttonMin {
  padding-top: 0.2em !important;
  padding-bottom: 0.09em !important;
  background-color: white !important;
  color: var(--primary-color) !important;
  height: 20px;
  margin-top: -18px !important;
}

.btn-success {
  color: var(--white-color) !important;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}
.btn-primary {
  color: var(--white-color) !important;
  background-color: var(--secondary-color) !important;
  border-color: var(--white-color) !important;
}
.panel-heading {
  background-color: var(--secondary-color) !important;
}
.panel-default .panel-heading {
  border-radius: 15px 15px 0 0;
  background-color: var(--secondary-color) !important;
  color: var(--white-color) !important;
  border: none !important;
}
.panel {
  /* border-radius: 15px; */
  border: none !important;
}
.panelheadbtn {
  background-color: var(--secondary-color) !important;
  color: var(--white-color) !important;
  border: none;
  margin-top: -14px !important;
}
.panelheadbtn:hover {
  cursor: pointer;
}
.table-condensed > tbody > tr > td {
  padding: 10px !important;
  /* word-break: break-word; */
  overflow-wrap: anywhere;
}

.minimal-btn-grp{
  display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 13px;
    gap: 13px;
}

.moreBtn {
  background-color: var(--primary-color);
  color: white;
  border: none;
  outline: none;
  /* float: right; */
  padding: 7 25px 7 25px;
  /* margin: 0 20 10 0; */
  /* position: relative;
  right: 45px; */
  border-radius: 2px;
}
.zoomBtn {
  color: var(--primary-color);
  background-color: var(--white-color);
  border: 1px solid var(--primary-color);
  outline: none;
  float: left;
  padding: 6 15px 6 15px;
  /* margin: 0 0 10 20; */
  /* position: absolute;
  left: 63px; */
  border-radius: 2px;
}

.streetBtn{
  background-color: var(--primary-color);
  color: white;
  border: none;
  outline: none;
  padding: 6 15px 6 15px;
}

.btnP {
  background-color: var(--white-color);
  border: 10px;
  color: var(--primary-color);
  /* padding: 4px 4px 4px; */
}

.detailedInfoPanel {
  z-index: 1;
  position: absolute;
  border: 1px solid black;
  border: 0px;
  left: 10px;
  margin-top: 12px;
  width: 300px;
  top: 100px;
}

.detailedInfoContent {
  font-size: 12px !important;
}

.infobutton {
  padding-top: 0.24em !important;
  padding-bottom: 0.16em !important;
}

.Count_details {
  height: 25px;
  margin-bottom: 5px;
  background-color: var(--grey);
}

.padding {
  padding-bottom: 5px;
  padding-top: 9px;
  margin-top: 15px;
}

.featureInfoPanelBody {
  max-height: calc(85vh - 79px);
  overflow-x: auto;
  overflow-y: auto;
  padding: 2px;
}

.featureInfoFooter {
  position: fixed;
  bottom: 0;
  width: 350px;
}

#sidebar {
  padding: 15px 10px;
  border: none;
  border-radius: 0;
  margin-bottom: 40px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.imageModel .modal-open .modal {
  overflow-y: hidden !important;
}

.imageModel {
  margin-top: 20px;
  border-radius: 10% 10% 10% 10%;
}

.imageModelHeader {
  color: white;
  background-color: var(--secondary-color);
  text-align: center;
}

.imageModelFooter {
  color: var(--white-color);
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}

.imageContainer img {
  max-height: 100%;
  max-width: 100%;
}

.imageContainerModel {
  max-height: 631px !important;
  overflow-y: auto;
}

.show-gridsss {
  width: 330px !important;
  margin-top: 5%;
}

.progresbar {
  padding-right: 0%;
  padding-left: 8%;
}

.progresbarname {
  padding-right: 0%;
  padding-left: 6%;
}

.dpanelheadbtn {
  background-color: var(--secondary-color) !important;
  color: var(--white-color) !important;
  border: none;
  margin-top: -19px !important;
  margin-left: 10px;
}

.pdfModel {
  margin-top: 30px;
  border-radius: 10% 10% 10% 10%;
}

.pdfModelHeader {
  color: var(--white-color);
  background-color: var(--secondary-color);
  text-align: center;
}

.pdfModelFooter {
  color: var(--white-color);
  border-color: var(--secondary-color);
}

.pdfContainer img {
  max-height: 100%;
  max-width: 100%;
}

.pdfContainerModel {
  padding-bottom: 56.25%;
  position: relative;
  display: block;
  width: 100%;
  height: 0;
}

.pdf_iframe {
  overflow-y: auto;
  padding: 2px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #d3d3dd #f2f5fb;
}

*::-webkit-scrollbar {
  width: 7px;
  height: 11px;
}

*::-webkit-scrollbar-track {
  background: #f2f5fb;
}

*::-webkit-scrollbar-thumb {
  background-color: #d3d3dd;
  border-radius: 20px;
  border: 1px solid #f2f5fb;
}

.pdfBotton {
  margin: 0 auto 10px auto;
  width: 56%;
}

.image-rotate {
  /* IE 9 */
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.label-primary {
  background-color: var(--label) !important;
}

.photoheadbtn {
  background-color: var(--secondary-color) !important;
  color: var(--white-color) !important;
  border: none;
  margin-top: 4px !important;
}


/* Style for DetailInfoTable */
.detail-info-table {
  width: 100%;
  border-collapse: collapse;
}

.detail-info-table td {
  border: 1px solid #ddd;
  padding: 8px;
  width: 50%;
}

.detail-info-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.detail-info-table tr:hover {
  background-color: #ddd;
}

.detail-info-table .floor-area-header {
  font-size: 14px;
  background-color: #ca2208;
  text-align: center;
  font-weight: bolder;
  padding: 8px;
}

.detail-cell {
  font-size: 12px;
  width: 50%;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.extendButtonMeasure {
  bottom: 7.4em;
  left: 17px;
}
@media only screen and (max-width: 425px) {
  .extendButtonMeasure {
    left: 5px;
  }
}

.measurePanels {
  z-index: 1;
  position: absolute;
  bottom: 34px;
  left: 66px;
  width: 253px;
  height: 163px;
}
.extendMeasureButton {
  top: 4em;
  left: 50%;
  border-radius: 20px !important;
  font-size: 2em;
  background-color: var(--white-color);
  margin-bottom: 5px;
  line-height: 1.4em;
  transition: background-color 0.3s;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
  outline: none;
  padding: 0px;
  height: 1.375em;
  width: 1.375em;
  border: none;
  color: red;
}

.measureClearButton {
  width: 50%;
  margin-left: 25%;
  justify-content: center;
  display: flex;
}
.extendMeasureButton:hover img {
  -webkit-filter: invert(100%) brightness(599%);
}
.extendMeasureButton:hover {
  background-color: var(--primary-color);
}

@media (max-width: 992px) {
  .measurePanels {
    z-index: 503;
    border: 1px solid black;
    position: absolute;
    border: 0px;
    right: 10%;
    left: 10%;
    bottom: 3% !important;
    width: 50%;
  }
  .measurePanelsBody {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
  }
}
@media (max-width: 700px) {
  .measurePanels {
    z-index: 503;
    border: 1px solid black;
    position: absolute;
    border: 0px;
    right: 10%;
    left: 10%;
    bottom: 3% !important;
    width: 60%;
  }
  .measurePanelsBody {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
  }
}

.cols {
  justify-content: center;
  display: flex;
}

.measureButtonContainer {
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
}
.measureButtons {
  float: left;
  width: 33%;
  justify-content: center;
  display: flex;
}
.measureResultContainer {
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  justify-content: center;
  display: flex;
}
.measureResult {
  justify-content: center;
  display: flex;
}

.btn-success {
  color: var(--white-color) !important;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}
.btn-primary {
  color: var(--white-color) !important;
  background-color: var(--secondary-color) !important;
  border-color: var(--white-color) !important;
}
.panel-default .panel-heading {
  border-radius: 15px 15px 0 0;
  background-color: var(--secondary-color) !important;
  color: var(--white-color) !important;
  border: none !important;
  margin-bottom: 10px;
}
.panel {
  border: none !important;
}
.panelheadbtn {
  background-color: var(--secondary-color) !important;
  color: var(--white-color) !important;
  border: none;
  margin-top: -14px !important;
}
.panelheadbtn:hover {
  cursor: pointer;
}
.panel-footer {
  border-radius: 0 0 15px 15px !important;
  background-color: white !important;
}

.extendButtonExport {
  bottom: 3.8em;
  left: 17px;
}
@media only screen and (max-width: 425px) {
  .extendButtonExport {
    left: 5px;
  }
}

.exportPanels {
  z-index: 1;
  position: absolute;
  bottom: 8px;
  left: 66px;
  width: 400px;
  height: auto;
}
.exportPanelsBody {
  max-height: calc(100vh - 250px);
  overflow-y: auto;
}
.btn_select {
  margin: 0px 5px 0 0px;
  height: 33px;
  background-color: var(--white-color);
  border-color: var(--primary-color);
}
.btn_submit {
  margin: 24px 5px 0 20px;
  height: 33px;
}

@media (max-width: 1024px) {
  .exportPanels {
    z-index: 1;
    position: absolute;
    bottom: 8px;
    left: 66px;
    width: 400px;
    height: auto;
  }
  .exportPanelsBody {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
  }
}
@media (max-width: 992px) {
  .exportPanels {
    z-index: 503;
    border: 1px solid black;
    position: absolute;
    border: 0px;
    right: 10%;
    left: 10%;
    bottom: 6px;
    width: 50%;
  }
  .exportPanelsBody {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
  }
}
@media (max-width: 700px) {
  .exportPanels {
    z-index: 503;
    border: 1px solid black;
    position: absolute;
    border: 0px;
    right: 10%;
    left: 10%;
    bottom: 5px !important;
    width: 60%;
  }
  .exportPanelsBody {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
  }


  .map {
    width: 100%;
    height: 400px;
  }
  a.skiplink {
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0;
    border: 0;
    height: 1px;
    width: 1px;
    overflow: hidden;
  }
  a.skiplink:focus {
    clip: auto;
    height: auto;
    width: auto;
    background-color: #fff;
    padding: 0.3em;
  }
  #map:focus {
    outline: #4A74A8 solid 0.15em;
  }
}

.addAsset {
  bottom: 9.1em;
  left: 17px;
}
@media only screen and (max-width: 425px) {
  .addAsset {
    left: 5px;
  }
}

.addassetpanel {
  z-index: 1;
  position: absolute;
  bottom: 40px;
  left: 66px;
  width: 253px;
  height: 163px;
}

.addassetpanelButton {
  width: 50%;
  margin-left: 25%;
  justify-content: center;
  display: flex;
  margin-top: 24px;
}

@media (max-width: 992px) {
  .addassetpanel {
    z-index: 503;
    border: 1px solid black;
    position: absolute;
    border: 0px;
    right: 10%;
    left: 10%;
    bottom: 3% !important;
    width: 50%;
  }
  .addassetpanelsBody {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
  }
}
@media (max-width: 700px) {
  .addassetpanel {
    z-index: 503;
    border: 1px solid black;
    position: absolute;
    border: 0px;
    right: 10%;
    left: 10%;
    bottom: 3% !important;
    width: 60%;
  }
  .addassetpanelsBody {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
  }
}

.gotoXY {
  bottom: 5.6em;
  left: 17px;
}
@media only screen and (max-width: 425px) {
  .gotoXY {
    left: 5px;
  }
}

.gotoPanels {
  z-index: 1;
  position: absolute;
  bottom: 59px;
  left: 63px;
  width: 253px;
  height: 163px;
}
@media (max-width: 992px) {
  .gotoPanels {
    z-index: 503;
    border: 1px solid black;
    position: absolute;
    border: 0px;
    right: 10%;
    left: 10%;
    bottom: 3% !important;
    width: 50%;
  }
  .gotoPanelsBody {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
  }
}
@media (max-width: 700px) {
  .gotoPanels {
    z-index: 503;
    border: 1px solid black;
    position: absolute;
    border: 0px;
    right: 10%;
    left: 10%;
    bottom: 3% !important;
    width: 60%;
  }
  .gotoPanelsBody {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
  }
}

.gotoPanelsButtons {
  width: 50%;
  margin-left: 25%;
  justify-content: center;
  display: flex;
}

.settingsBtn {
  padding: 3px;
  border-color: var(--white-color) !important;
  background-color: var(--white-color) !important;
  margin-left: 15px !important;
  margin-top: 5px;
  margin-bottom: 7px;
  color: #2b2b2b;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  outline: none;
}
.settingsBtn:hover {
  color: var(--primary-color);
}
.settingsBtn:focus {
  outline-style: none !important;
  box-shadow: none;
  outline: none !important;
}
.settingsImg {
  margin: -4px -7px 0 17px;
}
.settingsImg2 {
  margin: -4px -7px 0 17px;
  width: 15px;
  height: 17px;
}

.localbodyBtn {
  padding: 3px;
  border-color: var(--white-color) !important;
  background-color: var(--white-color) !important;
  margin-left: 15px !important;
  margin-top: 5px;
  margin-bottom: 7px;
  color: #2b2b2b;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  outline: none;
}
.localbodyBtn:disabled {
  color: #2b2b2b !important;
}
.localbodyBtn:focus {
  outline-style: none !important;
  box-shadow: none;
  outline: none !important;
}
.localbodyImg {
  margin: -4px -7px 0 17px;
}

.cngpass {
  top: 162px;
  left: 470px;
  width: 426px !important;
  border-radius: 15px;
  box-shadow: none !important;
  border: none !important;
}
.cngpassmodal {
  top: 97px;
  margin-bottom: 0px;
  width: 426px;
  left: 35%;
}
.panelBody {
  max-height: calc(70vh - 170px);
  min-height: 60px;
  overflow-y: auto;
  border-radius: 15px !important;
}
/* .pull-btn{
    margin-left: 320 !important;
  } */

.countButton {
  bottom: 0.5em;
  right: 0.5em;
}
@media only screen and (max-width: 425px) {
  .countButton {
    right: 5px;
  }
}

.listStyleUl {
  list-style: square;
  list-style-position: outside;
  padding-left: 2px;
}
.listStyleLi {
  padding-left: 6px;
}
.getCountPanels {
  z-index: 1;
  position: absolute;
  border: 0px;
  right: 1em;
  bottom: 3em;
  margin: auto;
  width: 300px;
  margin-top: 5px;
}
.getCountPanelsBody {
  max-height: calc(80vh - 250px);
  overflow-y: auto;
  padding: 4px 0px 10px 30px;
}
@media (max-width: 992px) {
  .getCountPanels {
    z-index: 503;
    border: 1px solid black;
    position: absolute;
    border: 0px;
    right: 10%;
    left: 10%;
    bottom: 3% !important;
    width: 50%;
  }
  .getCountPanelsBody {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
  }
}
@media (max-width: 700px) {
  .getCountPanels {
    z-index: 503;
    border: 1px solid black;
    position: absolute;
    border: 0px;
    right: 10%;
    left: 10%;
    bottom: 3% !important;
    width: 60%;
  }
  .getCountPanelsBody {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
  }
}

.currentLocationButton {
  top: 9.2em;
  left: 17px;
}
@media only screen and (max-width: 425px) {
  .currentLocationButton {
    left: 5px;
  }
}

.buffer {
  bottom: 2.1em;
  left: 17px;
}
@media only screen and (max-width: 425px) {
  .buffer {
    left: 5px;
  }
}

.marginSelect {
  margin-left: 10px;
  margin-right: 10px;
}
.marginRange {
  margin-top: 10px;
}
.marginLabel {
  margin-top: 10px;
}

.bufferPanels {
  z-index: 1;
  position: absolute;
  bottom: 8px;
  left: 66px;
  width: 350px;
  height: auto;
}
@media (max-width: 992px) {
  .bufferPanels {
    z-index: 503;
    border: 1px solid black;
    position: absolute;
    border: 0px;
    right: 10%;
    left: 10%;
    bottom: 3% !important;
    width: 50%;
  }
  .bufferPanelsBody {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
  }
}
@media (max-width: 700px) {
  .bufferPanels {
    z-index: 503;
    border: 1px solid black;
    position: absolute;
    border: 0px;
    right: 10%;
    left: 10%;
    bottom: 3% !important;
    width: 60%;
  }
  .bufferPanelsBody {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
  }
}

.bufferReportContainerModel {
  max-height: 300px !important;
  /* min-height: 261px !important; */
  overflow-y: auto;
  overflow-x: auto;
}
.excelName {
  padding: 7px 15px;
  margin: 8px 3px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.excelNam {
  padding: 7px 15px;
  margin: 8px 3px;
  display: inline-block;
  font-weight: bold;
  box-sizing: border-box;
}
.jumbo {
  padding: 0 0 0 0 !important;
  background-color: #ccc;
}
.pdfModelResult {
  margin-top: 70px;
  border-radius: 10% 10% 10% 10%;
}
.pdfModelResult .modal-open .modal {
  overflow-y: hidden !important;
}
.pdfModelResultHeader {
  color: white;
  background-color: var(--secondary-color);
  text-align: center;
  border-radius: 15px 15px 0 0;
}
.pdfModelResultFooter {
  color: var(--colorsecondary1);
  border-color: var(--colorprimary);
}
.pdfheadbtn {
  background-color: var(--secondary-color) !important;
  color: var(--secondary-color) !important;
  border: none;
  margin-top: 5px !important;
}
.modal-content {
  border-radius: 18px !important;
}

.search-popup{
    position: absolute;
    z-index: 2;
    background-color: white;
    -webkit-filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
    filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
    padding: 0px;
    border-radius: 15px;
    /* bottom: 12px; */
    /* left: -50px; */
    min-width: 280px;
    top: 23%;
    right: 4%;
}
.upload-widget-button {
    /* bottom: 2.5em;
    right: 0.5em; */
    /* position: relative; */
    position: absolute;
    bottom: 8em;
    right: 4em;
}

.popup-options {
    position: absolute;
    bottom: 3.5em;
    right: 4.5em;
    background-color: #ca2208;
    border: 1px solid #ca2208;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    padding: 10px;
    z-index: 10;
}

.popup-option {
    display: block;
    margin: 5px 0;
    padding: 8px 12px;
    background-color: white;
    color: #ca2208;
    border: 1px solid #000000;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.popup-option:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.popup-option:not(:disabled):hover {
    background-color: #ca2208;
    color: white;
    
}

@media only screen and (max-width: 425px) {
    .upload-widget-button {
        right: 5px;
    }

    .popup-options {
        right: 5px;
    }
}

.nav-bar .navbar {
  z-index: 2 !important;
  position: absolute;
  width: 100%;
  background-color: var(--primary-color);
}
.nav-bar .navbar-default .navbar-brand {
  color: white;
}
.nav-bar .navbar-default .navbar-nav > li > a {
  color: white;
}
.left-right-svg-div {
  text-align: center;
}
.left-right-svg-div svg {
  margin-top: 25px;
  color: green;
}
.title {
  margin-left: 21px;
  margin-top: 18px;
  margin-bottom: 21px;
  font-size: 23px;
}
.view-search-button {
  box-shadow: 0 1px 4px rgb(0 0 0 / 60%);
  width: 25px;
  z-index: 1 !important;
  position: absolute;
  top: 18px;
  left: 0px;
  /* height: 75px; */
  height: 66px;
  border-radius: 0px 6px 6px 0px;
  padding: 0px !important;
  background-color: white;
  color: white;
  margin-bottom: 0px !important;
}
.jumboBox {
  /* width: 622px; */
  width: 750px;
  z-index: 1 !important;
  position: absolute;
  top: 18px;
  left: 20px;
  /* height: 75px; */
  height: 66px;
  border-radius: 6px;
  padding: 0px !important;
  background-color: var(--primary-color);
  color: white;
  margin-bottom: 0px !important;
}
.toolbarResponsive {
  width: -webkit-max-content;
  width: max-content;
  margin: auto;
}
.collapseResponsive {
  height: 127px !important;
}
@media only screen and (min-width: 769px) {
  .toolIcons {
    z-index: 1;
  }
}

@media only screen and (max-width: 992px) {
  .jumboBox {
    width: 400px;
  }
}
@media only screen and (max-width: 768px) {
  .jumboBox {
    width: 100%;
    top: 0px;
    left: 0px;
    /* display: none; */
  }
}
@media only screen and (min-width: 768px) {
  .navbar-collapse.collapse {
    height: 90px !important;
  }
  /* .navbar-nav {
    float: none !important;
} */
}

.toolBarButtons {
  position: absolute;
  z-index: 1;
  border-radius: 27px !important;
  font-size: 2em;
  background-color: var(--white-color);
  outline: none;
  display: inline-block;
  padding: 0px;
  height: 40px;
  width: 40px;
}
.toolIcons:hover img {
  -webkit-filter: invert(100%) brightness(599%);
}
.toolIcons:hover {
  background-color: var(--primary-color) !important;
}
.toolIcons {
  font-size: 12px !important;
  background-size: cover;
  /* z-index: 1; */
  position: absolute;
  background-color: white !important;
  border-radius: 27px !important;
  width: 40px;
  height: 40px;
  border: none;
  outline: none;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
}
.navIcons {
  width: 35px;
  height: 35px;
  font-size: 12px !important;
  background-size: cover;
  position: absolute;
  z-index: 1;
  margin-left: 20px;
  /* top: 8px; */
  background-color: #9c1804 !important;
  border-radius: 20px !important;
}

.black {
  color: black;
}

.navbar-semi .navbar-nav.social-links li a {
  border-width: 0;
  padding-left: 3px;
  padding-right: 3px;
}

.appHeader {
  margin-bottom: 0px;
  border-radius: 0px;
  background: var(--blue);
  height: 50px;
  border: 0px;
}
.appNavBar,
.appNavBar a {
  height: 40px;
}

.appNavBar li a {
  padding: 15px 5px;
}

.input-group-addon,
.input-group-btn {
  vertical-align: top;
}

.navbar-nav {
  margin: 0;
}

.navbar-inverse .navbar-collapse,
.navbar-inverse .navbar-form {
  border: 0;
}

.navbar-semi {
  /* min-height: 40px; */
  background: "#7C4579";
}

.navbar-semi.navbar-fixed-top,
.navbar-semi.navbar-static-top {
  border-radius: 0;
}

.navbar-semi .navbar-toggle {
  border-color: var(--white-color);
  padding: 0;
  margin-top: 11px;
}

.navbar-semi .navbar-toggle .icon-bar {
  background-color: var(--white-color);
  height: 3px;
}

.navbar-semi .navbar-brand {
  font-weight: bold;
  color: var(--white-color);
  height: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.navbar-semi .navbar-nav > li:nth-child(1) {
  border-left: 0px solid var(--white-color);
}

.navbar-semi .navbar-nav > li > a {
  padding-top: 5px;
  padding-bottom: 5px;
  border-right: 0px solid #000;
}

@media (max-width: 768px) {
  .navbar-semi .navbar-nav > li > a {
    box-shadow: inset 0 0 0 rgba(255, 255, 255, 0.53);
    -webkit-box-shadow: inset 0 0 0 rgba(255, 255, 255, 0.53);
  }
}

.navbar-semi .navbar-nav > li > a:hover,
.navbar-semi .navbar-nav > li > a:focus {
  background-color: rgb(19, 121, 236);
}

@media only screen and (max-width: 768px) {
  .navbar-semi .navbar-nav.social-links {
    text-align: center;
  }
  .navbar-semi .navbar-nav.social-links li {
    display: inline-block;
    border-width: 0;
    padding-left: 5px;
    padding-right: 5px;
  }
  .navbar-semi .navbar-nav.social-links li a {
    border-width: 0;
  }
}

.navbar-semi .nav .open > a:focus {
  border-width: 0;
}

.navbar-semi .dropdown-menu {
  left: -2px;
  background-color: #101010;
  cursor: pointer;
}

.navbar-semi .dropdown-menu > li > a {
  padding: 7px 18px;
  color: var(--white-color);
  transition: all 300ms;
  cursor: pointer;
}

.navbar-semi .dropdown-menu > li > a:hover,
.navbar-semi .dropdown-menu > li > a:focus {
  background-color: #1d75bc;
  box-shadow: inset 5px 0 0 var(--white-color);
  cursor: pointer;
}

.navbar-semi .navbar-form {
  padding: 0px;
  margin: 3px -28px 0 3px;
}

.navbar-semi .navbar-form .form-control {
  width: 200px;
  background-color: #101010;
  border-color: #2a2a2a;
  transition: width 300ms;
  height: 28px;
  top: 2px;
}

.navbar-semi .navbar-form .form-control:focus {
  /*  width: 250px; */
  box-shadow: 0 0 0, 0 0 0;
}

@media (max-width: 768px) {
  .navbar-semi .navbar-form {
    margin: 0;
  }
  .navbar-semi .navbar-form .form-control {
    width: 100%;
  }
  .navbar-semi .navbar-form .form-control:focus {
    width: 100%;
  }
}

.navbar-semi .navbar-form .form-control {
  background-color: var(--white-color);
  border-color: var(--white-color);
}

@media (max-width: 400px) {
  .navbar-semi .navbar-form {
    margin: 0;
  }
  .navbar-semi .navbar-form .form-control {
    width: 100%;
  }
  .navbar-semi .navbar-form .form-control:focus {
    width: 100%;
  }

  .navbar-semi .navbar-nav.social-links {
    text-align: center;
  }
  .navbar-semi .navbar-nav.social-links li {
    display: inline-block;
    border-width: 0;
    width: 40px;
  }
  .navbar-semi .navbar-nav.social-links li a {
    border-width: 0;
    width: 40px;
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (max-width: 992px) {
  .navbar-header {
    float: none;
  }
  .navbar-left,
  .navbar-right {
    float: none !important;
  }
  .navbar-toggle {
    display: block;
  }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  }

  .navbar-collapse.collapse {
    display: none !important;
  }

  .navbar-nav > li {
    float: none;
  }
  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .collapse.in {
    display: block !important;
  }

  .navbar-semi .navbar-nav.social-links {
    text-align: center;
  }
  .navbar-semi .navbar-nav.social-links li {
    display: inline-block;
    border-width: 0;
    padding-left: 10px;
    padding-right: 10px;
  }
  .navbar-semi .navbar-nav.social-links li a {
    border-width: 0;
    padding-top: 3px;
    padding-bottom: 2px;
  }

  .navbar-semi .navbar-form {
    margin: 0;
  }
  .navbar-semi .navbar-form .form-control {
    width: 100%;
  }
  .navbar-semi .navbar-form .form-control:focus {
    width: 100%;
  }
}
.black {
  color: black;
}
.navBarForm {
  background-color: white;
  border-color: white;
  border-radius: 0px 5px 5px 0px;
}
#navBarFormButton {
  display: inline-block;
  padding: 6px 12px;
  margin-top: 2px;
  font-size: 11px;
  height: 28px;
  font-weight: 400;
  /* line-height: 1.42857143; */
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  /* cursor: pointer; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  /* user-select: none; */
  /* background-image: none; */
}

.form-control {
  display: block;
  width: 100%;
  top: 3px;
  height: 27px;
  padding: 1px 14px;
  font-size: 12px;
  line-height: 1.42857143;
  color: #555;
  background-color: var(--white-color);
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.rbt-input-hint {
  display: none;
}
.rbt-aux .rbt-close {
  margin-top: 5px !important;
}
.rbt .rbt-input-hint-container {
  height: 35px;
}
.input-group > .rbt:not(:last-child) .form-control {
  height: auto;
  border: none;
}

.search {
  position: absolute;
  bottom: 6.6em;
  left: 34px;
  z-index: 1;
  border-radius: 27px !important;
  font-size: 2em;
  background-color: var(--white-color);
  margin-bottom: 376px;
  line-height: 1.4em;
  transition: background-color 0.3s;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
  outline: none;
  display: inline-block;
  padding: 0px;
  color: var(--primary-color);
  height: 40px;
  width: 40px;
  border: none;
}
.search:hover img {
  -webkit-filter: invert(100%) brightness(599%);
}
.search:hover {
  background-color: var(--primary-color);
}

.qsearch {
  z-index: 1;
  position: absolute;
  top: 15px !important;
  margin-left: 115px !important;
  margin-right: 80px !important;
}

@media only screen and (max-width: 768px) {
  .qsearch {
    margin-left: 13px !important;
  }
  .navbar-nav .open .dropdown-menu {
    background-color: white !important;
    position: absolute !important;
    border: none !important;
    z-index: 10 !important;
  }
}

#navBarFormButton:disabled {
  background-color: white !important;
  color: grey !important;
  opacity: 1 !important;
}

.form-control {
  background-color: white !important;
  opacity: 1 !important;
  top: 2px !important;
  box-shadow: none !important;
}

.form-control[disabled] {
  background-color: white !important;
  opacity: 1 !important;
}

.advancedfilter {
  border-radius: 20px !important;
  /* background-color: #9C1804 !important; */
  background-color: yellow !important;
}
.dropdown-menu {
  /* border-radius: 12px !important; */
  font-family: "Roboto", sans-serif;
  color: #2b2b2b;
  min-width: 140px !important;
}
.dropdown-menu:hover {
  cursor: pointer !important;
}
.dropdown-menu > li {
  padding: 4 0 4 0;
}

.advancedFilterModal {
  position: absolute;
  top: 50%;
  left: 28%;
  margin: -213px 0 0 -213px;
  width: 900px !important;
  border-radius: 15px;
  box-shadow: none !important;
  border: none !important;
}
.modal-backdrop.in {
  opacity: 0 !important;
}
.advancedFilterModal .modal.in .modal-dialog {
  width: 900px !important;
  border-radius: 15px !important;
}
.advancedFilterModal .modal-content {
  border-radius: 18px !important;
  width: 750px !important;
}
.advancedFilterPanel {
  margin-bottom: 0px;
  width: 900px;
}
.model-header {
  background-color: var(--secondary-color)!important;
  color: #fff;
  padding: 10px 15px;
  border-bottom: 1px solid transparent;

}
.modal-tile {
  font-size: 21px;
  margin: -4px
}
@media only screen and (max-width: 768px) {
  .advancedFilterModal {
    top: 50%;
    left: 50%;
    margin: -164px 0 0 -164px;
    overflow-x: scroll;
    width: 328px !important;
  }
  .advancedFilterModal .modal.in .modal-dialog {
    width: 312px !important;
  }
  .advancedFilterModal .modal-content {
    width: 312px !important;
  }
  .advancedFilterPanel {
    width: 312px !important;
  }
}

.panelBodys {
  /* max-height: calc(70vh - 170px); */
  min-height: 60px;
  /* overflow-y: auto; */
  border-radius: 15px !important;
  padding: 25px !important;
}

/* width */
/* ::-webkit-scrollbar {
  width: 12px;
} */
.report {
  margin-left: 3px;
}
.addfilterbtn {
  padding: 3px;
  border-color: var(white-color) !important;
  background-color: var(white-color) !important;
  margin-left: 15px !important;
  color: var(--white-color);
}

.reportFilterModal {
  top: 20px;
}
.reportFilterPanel {
  margin-bottom: 0px;
}
.report {
  margin-left: 3px;
}

.filterFilterModal {
  top: 60px;
}
.filterFilterPanel {
  margin-bottom: 0px;
}

.filterFilterModal .modal-content{
  width: 300px !important;
}
.filterFilterModal .modal.in .modal-dialog{
  width: 300px !important;
}

.reportContainerModel {
  max-height: 300px !important;
  /* min-height: 261px !important; */
  overflow-y: auto;
  overflow-x: auto;
}
.excelName {
  padding: 7px 15px;
  margin: 8px 3px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.pdfModelResult {
  margin-top: 70px;
  border-radius: 10% 10% 10% 10%;
}
.pdfModelResultHeader {
  color: white;
  background-color: var(--secondary-color);
  text-align: center;
  border-radius: 15px 15px 0 0;
}
.pdfModelResultFooter {
  color: var(--colorsecondary1);
  border-color: var(--colorprimary);
}
.pdfheadbtn {
  background-color: var(--secondary-color) !important;
  color: var(--white-color) !important;
  border: none;
  margin-top: 5px !important;
}
.modal-content {
  border-radius: 18px !important;
}

@media only screen and (max-width: 992px) {
  #toolbar .nav li {
    display: inline;
    float: right;
    top: 9px;
  }
  #toolbar .nav li a {
    display: inline;
  }
}
@media only screen and (max-width: 768px) {
  #toolbar .nav li {
    display: inline;
    float: left;
  }
  #toolbar .nav li a {
    display: inline;
    /* left: 400px; */
    top: 9px;
  }
  /* #toolbar ul .navbar-nav {
    margin:0%;
  } */
}

.nav-scroller-wrapper {
  position: relative;
  overflow: hidden;
  padding: 0 30px;
}

.nav-scroller {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  font-size: 0;

  padding-bottom: 20px;
  margin-bottom: -20px;
}

.nav-scroller-content {
  position: relative;
  display: flex;
  justify-content: space-around;
  float: left;
  width: -webkit-min-content;
  width: min-content;
  min-width: 100%;
  transition: -webkit-transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
}

.no-transition {
  transition: none;
}

.nav-scroller-item {
  display: block;
  font-size: 1.125rem;
  text-transform: uppercase;
  text-align: center;
  padding: 0.5rem;
}

.nav-scroller-btn {
  position: absolute;
  top: 0;
  bottom: 0;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 1.25rem;
  transition: opacity 0.3s;

  /* &:not(.active) {
    opacity: 0;
    pointer-events: none;
  } */
}

.nav-scroller-btn--left {
  left: 0;
}

.nav-scroller-btn--right {
  right: 0;
}

.fullwidth {
  padding: 15px 0;
  margin: 15px 0;
  background-color: var(--secondary-color);
  position: absolute;
  z-index: 1;
}

.ward-table {

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
    padding-right: 50px;
    padding-left: 50px;
}

.ward-table-field-report {

    width: 100%;
    margin-top: 1rem;
    padding-right: 50px;
    padding-left: 50px;

}

.ward-head {
    display: flex;
    /* justify-content: space-between; */
    margin-bottom: 4rem;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 50px;
}

.ward-head-inner {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
    /* margin-right: 20%; */
}

.ward-head p {
    font-size: 12px;
}

.ward-head h1 {
    margin-top: 2rem;
    font-size: 17px;
    font-weight: bolder;

    /* text-decoration: underline black 3px; */
}

.ward-head h1:nth-child(3) {
    margin-top: 2rem;
    font-size: 13px;
    font-weight: bolder;
}

.ward-content {
    margin-bottom: 2rem;
    padding-left: 50px;
}

.ward-info {
    display: flex;
    justify-content: space-between;
}

.ward-info div {
    width: 30%;
}

.ward-table,
.ward-table-field-report {
    border-color: black;
}



.scrollable-container {
    /* max-height: 500px; */
    overflow-y: auto;
    margin: 10px;
    padding: 5px;
}


.mdb-table {
    border-color: black;
}

.mdb-table th {
    font-size: 12px;
    color: black;
}

.mdb-table td {
    height: 45px;
    font-size: 12px;
    color: black;
}

.mdb-table2 {
    border-color: black;
}

.mdb-table2 tr {
    height: 70px;
    width: 70px;
}

.mdb-table2 th {
    font-size: 12px;
    color: black;
    height: 70px;
    width: 70px;
}

.mdb-table2 td {
    text-align: left;
    font-size: 12px;
    color: black;
}

.text-sm-start {
    font-size: 12px;
    padding-left: 50px;
    color: black;
}

.data-collection-end {
    font-size: 12px;
    color: black;
}

.page-break {
    page-break-before: always;
}

.Data-collection-icon {
    display: flex;
    justify-content: flex-start;
    /* margin-top: 2rem; */
    margin-left: 2rem;
    cursor: pointer;
}

.download-icon {

    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    /* margin-top: 2rem; */
    margin-right: 2rem;

}

.Data-collection-dropdown-show {
    display: flex;
    justify-content: flex-start;

    margin-left: 2rem;


}




.data-collection-end {
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 10px;
}

.single-pdf-download .single-pdf-text {
    visibility: hidden;
}

.single-pdf-text {
    display: flex;
    justify-content: baseline;
    justify-content: center;
    font-size: 12px;
    width: 70px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
}


.single-pdf-download:hover .single-pdf-text {
    visibility: visible;
}

.multiple-pdf-download .multiple-pdf-text {
    visibility: hidden;
}

.multiple-pdf-text {
    display: flex;
    justify-content: baseline;
    justify-content: center;
    font-size: 12px;
    width: 70px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

}

.multiple-pdf-download:hover .multiple-pdf-text {
    visibility: visible;
}

.footer-line {
    border: 2px solid grey;
    width: 60%;
    margin-block: 3rem !important;
}

/* .ward-table {

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
    padding-right: 50px;
    padding-left: 50px;
}

.ward-table-field-report {

    width: 100%;
    margin-top: 1rem;
    padding-right: 50px;
    padding-left: 50px;

}

.ward-head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 50px;
}

.ward-head-inner {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
}

.ward-head p {
    font-size: 12px;
}

.ward-head h1 {
    margin-top: 2rem;
    font-size: 17px;
    font-weight: bolder;
    text-decoration: underline black 3px;
}

.ward-head h1:nth-child(3) {
    margin-top: 2rem;
    font-size: 13px;
    font-weight: bolder;
}

.ward-content {
    margin-bottom: 2rem;
    padding-left: 50px;
}

.ward-info {
    display: flex;
    justify-content: space-between;
}

.ward-info div {
    width: 30%;
}

.ward-table,
.ward-table-field-report {
    border-color: black;
}



.scrollable-container {
  
    overflow-y: auto;
    margin: 10px;
    padding: 5px;
}


.mdb-table {
    border-color: black;
}

.mdb-table th {
    font-size: 12px;
    color: black;
}

.mdb-table td {
    height: 45px;
    font-size: 12px;
    color: black;
}

.mdb-table2 {
    border-color: black;
}

.mdb-table2 tr {
    height: 70px;
    width: 70px;
}

.mdb-table2 th {
    font-size: 12px;
    color: black;
    height: 70px;
    width: 70px;
}

.mdb-table2 td {
    text-align: left;
    font-size: 12px;
    color: black;
}

.text-sm-start {
    font-size: 12px;
    padding-left: 50px;
    color: black;
}

.data-collection-end {
    font-size: 12px;
    color: black;
}

.page-break {
    page-break-before: always;
}

.Data-collection-icon {
    display: flex;
    justify-content: flex-start;

    margin-left: 2rem;
    cursor: pointer;
}

.download-icon {

    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  
    margin-right: 2rem;

}

.Data-collection-dropdown-show {
    display: flex;
    justify-content: flex-start;

    margin-left: 2rem;


}




.data-collection-end {
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 10px;
}

.single-pdf-download .single-pdf-text {
    visibility: hidden;
}

.single-pdf-text {
    display: flex;
    justify-content: baseline;
    justify-content: center;
    font-size: 12px;
    width: 70px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
}


.single-pdf-download:hover .single-pdf-text {
    visibility: visible;
}

.multiple-pdf-download .multiple-pdf-text {
    visibility: hidden;
}

.multiple-pdf-text {
    display: flex;
    justify-content: baseline;
    justify-content: center;
    font-size: 12px;
    width: 70px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

}

.multiple-pdf-download:hover .multiple-pdf-text {
    visibility: visible;
}

.footer-line{
    border: 2px solid grey;
    width: 60%;
    margin-block: 3rem !important;
} */
.reportContainer {
  /* background: url("./images/kerala5.jpg") no-repeat center center fixed; */
  background-size: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  overflow-y: auto;
}
.reportTitle {
  width: 100% !important;
  padding: 15px;
  font-size: 24px;
  background: #f9f9fe;
  text-align: left;
  margin-bottom: 10px;
  border-radius: 12px 12px 0px 0px;
  color: #2b2b2b;
}
.back {
  margin-left: 30px;
  margin-right: 20px;
  height: 20px;
  width: 20px;
  cursor: pointer;
}
.reportCard {
  position: absolute;
  width: 90%;
  right: 5%;
  left: 5%;
  /* height: 100%; */
  /* max-height: calc(100vh - 100px); */
  margin-top: 10px;
  background: #f9f9fe;
  border-radius: 12px;
  transition: all 0.2s ease-in-out;
}
.selectLayerRow {
  margin-top: 15px;
  padding: 10px;
}
.selectLayer {
  margin-left: 20px;
}
.addfilterbutton {
  padding: 3px;
  border-color: transparent !important;
  background-color: transparent !important;
  margin-top: 28px !important;
  color: var(--primary-color);
}
.addfilterbutton:disabled {
  cursor: not-allowed;
}
.btn_sel_col {
  margin: 24px 5px 0 20px;
  height: 33px;
  background-color: var(--white-color);
  border-color: var(--primary-color);
}
.btn_submit {
  margin: 24px 5px 0 20px;
  height: 33px;
}
.resultContainerModel {
  margin-top: 10px;
  max-height: 300px !important;
  overflow-y: auto;
  overflow-x: auto;
  margin-left: 20px;
}
.drp_layer {
  height: 34px;
}
.countBadge {
  padding: 5px 8px 5px 8px !important;
  margin-bottom: 1px !important;
  font-weight: 800;
  background-color: #72b872;
}
.excellButton {
  padding: 3px;
  font-size: 12px;
  line-height: 2;
  width: 100%;
  margin-top: 26px;
}
.excelNameReport {
  padding: 1px 14px;
  font-size: 14px;
  width: 100%;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.resultProgressBar {
  width: 100;
  justify-content: center;
  margin: auto;
}
.resultProgressBarDiv {
  width: 100%;
  margin-top: 5px;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 7%;
}
.noDataTxt {
  text-align: center;
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/
@media (min-width: 1281px) {
}
/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/
@media (min-width: 1025px) and (max-width: 1280px) {
}
/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) {
}
/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}
/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/
@media (min-width: 481px) and (max-width: 767px) {
  .btn_addfilter {
    margin: 10px 5px 0 5px;
  }
  .btn_sel_col {
    margin: 10px 5px 0 5px;
  }
  .btn_submit {
    margin: 10px 5px 0 5px;
  }
}
/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/
@media (min-width: 320px) and (max-width: 480px) {
  .btn_addfilter {
    margin: 10px 5px 0 5px;
  }
  .btn_sel_col {
    margin: 10px 5px 0 5px;
  }
  .btn_submit {
    margin: 10px 5px 0 5px;
  }
}

.tabledetails {
  top: 162px;
  left: 470px;
  width: 600px !important;
  box-shadow: none !important;
  border: none !important;
}
.tabledetails .modal.in .modal-dialog {
  width: 600px !important;
}
.tabledetails .modal-content {
  width: 550px !important;
  align-content: center;
}
.tabledetailsPanel {
  margin-bottom: 0px;
  width: 550;
}
@media only screen and (max-width: 768px) {
  .tabledetails {
    top: 150px;
    left: 0px;
    overflow-x: scroll;
    width: 327px !important;
  }
  .tabledetails .modal.in .modal-dialog {
    width: 312px !important;
  }
  .tabledetails .modal-content {
    width: 312px !important;
  }
  .advancedFilterPanel {
    width: 312px !important;
  }
}

.panelBody {
  max-height: calc(70vh - 170px);
  min-height: 60px;
  overflow-y: auto;
}
/* width */
::-webkit-scrollbar {
  width: 12px;
}
.report {
  margin-left: 3px;
}
.addfilterbtn {
  padding: 3px;
  border-color: var(--white-color) !important;
  background-color: var(--white-color) !important;
  margin-left: 15px !important;
  color: var(--primary-color);
}

.panelBody {
  max-height: calc(70vh - 170px);
  min-height: 60px;
  overflow-y: auto;
}
/* width */
::-webkit-scrollbar {
  width: 12px;
}
.resultbutton {
  display: block;
  margin: auto;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 100%;
  border-radius: 7px;
  margin-top: 15px;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

img {
  border-radius: 5px 5px 0 0;
}

.container {
  padding: 2px 16px;
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.navbar-class {
  background-color: var(--primary-color);
  color: var(--white-color) !important;
  width: 100%;
  margin-bottom: 0px;
}

.navbar-brand {
  font-size: 25px;
  color: var(--white-color) !important;
}

.nav-link {
  color: var(--white-color) !important;
}

.navbar-inverse .navbar-nav>li>a {
  color: var(--white-color) !important;
}

.navbar-inverse {
  border-color: var(--primary-color);
}

.container-panel {
  overflow-x: scroll;
  width: 100%;
  height: 100%;
  padding-bottom: 20px;
}

.diagram-panel1 {
  width: 100%;
  text-align: center;
  /* height: 380px; */
  background-color: var(--white-color);
  border-radius: 12px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3), 0 2px 10px rgba(0, 0, 0, 0.22);
}

.diagram-panel2 {
  width: 100%;
  text-align: center;
  /* height: 380px; */
  background-color: var(--white-color);
  border-radius: 12px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3), 0 2px 10px rgba(0, 0, 0, 0.22);
}

.form-controls {
  margin-top: 6px !important;
  margin-bottom: 10px !important;
}

.dashboard-heading {
  margin-top: 0px !important;
  margin-bottom: 10px !important;
  margin-left: 10px !important;
  color: var(--secondary-color);
}

.resultProgressBarDiv {
  width: 100%;
  margin-top: 5px;
}

.resultProgressBar {
  width: 100;
  justify-content: center;
  margin: auto;
}

.count-panel {
  width: 100%;
  text-align: center;
  background-color: var(--white-color);
  border-radius: 12px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3), 0 2px 10px rgba(0, 0, 0, 0.22);
}

.count-panel-population {
  width: 100%;
  min-height: 50px !important;
  height: 50px;
  margin: 10px;
  text-align: center;
  background-color: var(--white-color);
  border-radius: 12px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3), 0 2px 10px rgba(0, 0, 0, 0.22);
}

.socio-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-map {
  position: relative;
  display: block;
  padding: 10px 15px;
}

/* Analysis Style */
/* .analysis-row{
    width: 100%;
    display: flex;
    justify-content: center;
    border-block: 4px solid grey;
}

.analysis-heading{
    font-size: 3rem;
    font-weight: 600;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.top-analysis-div{
  display: flex;
    justify-content: center;
}

.analysis-second-div{
    display: flex;
    gap: 1rem;
}
.analysis-dropdown-head{
  border-block: 4px solid #20130f;
  margin-bottom: 1rem;
} */
/* .underline{
    margin-top: 0.55rem;
    width: 15rem;
    height: 3px;
    background-color: black;
} */

.left-row {
  width: 15%;
  padding: 0.6rem;
}

.right-row {
  width: 15%;
  padding: 0.6rem;
}

.show-report-btn {
  margin-right: 4rem;
  width: 14%;
  padding: 0.8rem;
  background-color: #ec3131;
  outline: none;
  border: none;
  border-radius: 0.6rem;
  /* font-size: 1.6rem; */
  /* font-weight: 600; */
  color: white;
  cursor: pointer;
}

.show-report-btn:disabled {
  background-color: #df5c5c;
  cursor: not-allowed;
}

/* .div-bw-line{
  width: 90%;
  height: 3px;
  background-color: black;
} */

.about-div {}

.about-image-div {}

.about-image {
  height: 100%;
  width: 100%;
}

.about-discrtiption {}

.nav-tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 13px;
}

/* --- Second Dashboard --- */

.second-dashboard-main-row {
  height: 400px;
  margin: 10px 8px 0px 1px !important;
}

.second-dashboard-col {
  display: flex;
  flex-direction: column;
  /* grid-gap: 3px; */
  /* gap: 3px; */
  /* border: 1px solid; */
  padding: 0px !important;
}

.second-dashboard-btn {
  flex-direction: column !important;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  border: 1px solid #c4bfbfb5 !important;
  display: flex;
  align-items: center;
  padding: 2px;
  overflow: hidden;
}

.second-dashboard-slider-range {
  width: 70% !important;
}

.second-dashboard-heading {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: 500;
}

.second-dashboard-heading-key {
  font-size: 11px;
  color: grey;
  letter-spacing: .5px;
}

.second-dashboard-heading-value {
  font-size: 11px;
  color: black;
  font-weight: 600;
  margin-left: 7px;
}

.second-dashboard-doughnuts-values {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1px;
}

.second-dashoard-doughnut-start-value {
  font-weight: bold;
  font-size: 2rem;
}

.second-dashoard-doughnut-center-value {
  font-weight: bold;
  font-size: 2rem;
  color: #a8a4a4;
}

.second-dashoard-doughnut-end-value {
  font-weight: bold;
  font-size: 2rem;
}

.second-dashboard-doorStatusBarChart {
  flex-direction: column !important;
  width: 100%;
  border: 1px solid #c4bfbfb5 !important;
  display: flex;
  align-items: center;
  padding: 2px;
  /* height: 256px; */
  height: 160px;
  justify-content: space-around;
}

.second-dashboard-row {
  margin: 0px !important;
}

.second-dashboard-row-col {
  padding: 0px !important;
}

.second-dashboard-pie-chart-row {
  margin: 0px !important;
}

.doughnut-container {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 170px;
  max-height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.doughnut-container canvas {
  max-width: 100%;
  max-height: 100%;
}

.pie-container {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 180px;
  max-height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pie-container canvas {
  max-width: 100%;
  max-height: 100%;
}

.bar-container {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 400px;
  max-height: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bar-container canvas {
  max-width: 100%;
  max-height: 100%;
}

/* ----------------- */

@media only screen and (min-width: 2560px) {
  .diagram-panel1 {
    /* height: 700px; */
  }

  .diagram-panel2 {
    /* height: 700px; */
  }
}

@media only screen and (min-width: 1024px) {
  .navbar-class {
    height: 45px;
  }
}

@media only screen and (max-width: 770px) {
  .diagram-panel1 {
    /* height: 400px; */
  }

  .diagram-panel2 {
    /* height: 380px; */
  }
}

@media only screen and (max-width: 425px) {
  .diagram-panel1 {
    /* height: 300px; */
  }

  .diagram-panel2 {
    /* height: 380px; */
  }
}

@media only screen and (max-width: 325px) {
  .diagram-panel1 {
    /* height: 200px; */
  }

  .diagram-panel2 {
    /* height: 310px; */
  }
}

@media (min-width: 768px) {
  .nav-map {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
.analysis-cards{
    display: grid;
    grid-template-columns: repeat(4,1fr);
}
 
.road-icon-button{
    width: 40%;
    height: 8%;
    border: none;
    background: none;
}
 
 
.show-report-btn{
    font-size: 1.5rem;
    color: white;
    background-color: #cb3f3f;
    padding: 0.7rem;
    border-radius: 0.3rem;
    border: none;
}
 
.analysis-form-group{
    /* font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem; */
    font-size: 13px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0px;
   margin-top: 2px;
}

.analysis-form-group label{
    margin-bottom: 0px !important;
}

.analysis-form-group select{
    margin: 0px !important;
    height: 24px;
}
 
.left-analysis-dropdown{
    /* width: 17%;
    margin: 0; */
    width: 17%;
    margin: 0;
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    gap: 8px;
}
 
.left-first-div{
    width: 100%;
    display: flex;
    flex-direction: column;
    border: none;
    padding: 0px;
}
 
.heading-div{
    /* width: 100%;
    display: flex;
    justify-content: center;
    border-bottom: 3px solid black;
     margin-block: 1rem; 
    margin-block: 0px; */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    margin-block: 0px;
}

 .hr-tag{
    border: 1px solid black;
    width: 72%;
 }

.left-row-head{
    /* font-size: 21px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    margin-top: 1px !important;
    letter-spacing: .1rem; */
    font-size: 21px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    letter-spacing: .1rem;
    margin: 0px;
}
 
.center-analysis-div{
    width: 70%;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}
 
.center-top-div{
    width: 100%;
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    justify-content: center;
}
 
.map-div{
    border: 2px solid;
    height: 50%;
    width: 100%;
}
 
.center-bottom-div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
 
.center-bottom-dropdown{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
 
.right-analysis-div{
    width: 17%;
    margin: 0;
}
 
.analysis-buttons{
    padding: 1rem;
    background-color: #e13737;
    border: none;
    border-radius: 0.5rem;
    color: white;
    cursor: pointer;
    width: 100%;
}

.analysis-buttons:hover {
    background-color: #e13737; 
  }
 
  .analysis-buttons:disabled {
    background-color: #e13737;
   }
.range-text{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.center-dropdowns-row{
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Analysis Style */
.analysis-row{
    width: 100%;
    display: flex;
    justify-content: center;
    border-block: 4px solid grey;
}

.analysis-heading{
    font-size: 3rem;
    font-weight: 600;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.top-analysis-div{
  display: flex;
    justify-content: center;
}

.analysis-second-div{
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    max-height: 422px;
}
.analysis-dropdown-head{
  border-bottom: 4px solid #20130f;
  margin-bottom: 1rem;
}

.center-map-row{
    display: flex;
    align-items: center;
    justify-content: center;
    /* max-height: 349px; */
}

.left-row{
  width: 15%;
  padding: 0.6rem;
}

.right-row{
  width: 15%;
  padding: 0.6rem;
}

.show-report-btn{
  margin-right: 4rem;
  width: 14%;
  padding: 0.8rem;
  background-color: #ec3131;
  outline: none;
  border: none;
  border-radius: 0.6rem;
  /* font-size: 1.6rem; */
  /* font-weight: 600; */
  color: white;
 cursor: pointer;
}
.show-report-btn:disabled{
  background-color: #df5c5c;
  cursor: not-allowed;
}

/* --------------- */

.analysis-main-row{
    display: flex;
    width: 100%;
    grid-gap: 7px;
    gap: 7px;
}

.analysis-left-dropdown{
    /* border: 1px solid; */
    /* flex-direction: column; */
    padding-left: 5px;
    width: 20%;
}

.analysis-left-head{
    font-size: 13px !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    font-weight: 700;
}

.analysis-head-line{
    border: 1px solid #838383;
    width: 85%;
    margin-top: 1px !important;
    margin-bottom: 4px !important;
}

.analysis-left-dropdowns{

}

.analysis-form-dropdown{
    margin-bottom: 0px !important;
    width: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.analysis-form-dropdown label{
    font-size: 12px;
    margin-bottom: 1px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.analysis-form-dropdown-input{
    font-size: 11px;
    /* font-weight: 100; */
    margin-bottom: 5px;
    max-height: 25px;
    width: 90% !important;
    
}

.analysis-btn-div{
    position: absolute;
    /* border: 1px solid; */
    bottom: 0px;
    width: 16%;
}

.analysis-btn{
    background-color: #e13737;
    border: none;
    border-radius: 0.5rem;
    color: white;
    cursor: pointer;
    width: 100%;
    height: 38px;
    margin-block: 2rem;
}

.analysis-center-dropdown{
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.analysis-center-head-line{
    border: 1px solid #838383;
    width: 85%;
    margin-top: 1px !important;
    margin-bottom: 4px !important;
}

.analysis-center-first-dropdowns{
    display: flex;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
    margin-inline: 4px;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.analysis-center-second-dropdowns{

}
