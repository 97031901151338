.ward-table {

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
    padding-right: 50px;
    padding-left: 50px;
}

.ward-table-field-report {

    width: 100%;
    margin-top: 1rem;
    padding-right: 50px;
    padding-left: 50px;

}

.ward-head {
    display: flex;
    /* justify-content: space-between; */
    margin-bottom: 4rem;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 50px;
}

.ward-head-inner {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
    /* margin-right: 20%; */
}

.ward-head p {
    font-size: 12px;
}

.ward-head h1 {
    margin-top: 2rem;
    font-size: 17px;
    font-weight: bolder;

    /* text-decoration: underline black 3px; */
}

.ward-head h1:nth-child(3) {
    margin-top: 2rem;
    font-size: 13px;
    font-weight: bolder;
}

.ward-content {
    margin-bottom: 2rem;
    padding-left: 50px;
}

.ward-info {
    display: flex;
    justify-content: space-between;
}

.ward-info div {
    width: 30%;
}

.ward-table,
.ward-table-field-report {
    border-color: black;
}



.scrollable-container {
    /* max-height: 500px; */
    overflow-y: auto;
    margin: 10px;
    padding: 5px;
}


.mdb-table {
    border-color: black;
}

.mdb-table th {
    font-size: 12px;
    color: black;
}

.mdb-table td {
    height: 45px;
    font-size: 12px;
    color: black;
}

.mdb-table2 {
    border-color: black;
}

.mdb-table2 tr {
    height: 70px;
    width: 70px;
}

.mdb-table2 th {
    font-size: 12px;
    color: black;
    height: 70px;
    width: 70px;
}

.mdb-table2 td {
    text-align: left;
    font-size: 12px;
    color: black;
}

.text-sm-start {
    font-size: 12px;
    padding-left: 50px;
    color: black;
}

.data-collection-end {
    font-size: 12px;
    color: black;
}

.page-break {
    page-break-before: always;
}

.Data-collection-icon {
    display: flex;
    justify-content: flex-start;
    /* margin-top: 2rem; */
    margin-left: 2rem;
    cursor: pointer;
}

.download-icon {

    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    /* margin-top: 2rem; */
    margin-right: 2rem;

}

.Data-collection-dropdown-show {
    display: flex;
    justify-content: flex-start;

    margin-left: 2rem;


}




.data-collection-end {
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 10px;
}

.single-pdf-download .single-pdf-text {
    visibility: hidden;
}

.single-pdf-text {
    display: flex;
    justify-content: baseline;
    justify-content: center;
    font-size: 12px;
    width: 70px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
}


.single-pdf-download:hover .single-pdf-text {
    visibility: visible;
}

.multiple-pdf-download .multiple-pdf-text {
    visibility: hidden;
}

.multiple-pdf-text {
    display: flex;
    justify-content: baseline;
    justify-content: center;
    font-size: 12px;
    width: 70px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

}

.multiple-pdf-download:hover .multiple-pdf-text {
    visibility: visible;
}

.footer-line {
    border: 2px solid grey;
    width: 60%;
    margin-block: 3rem !important;
}

/* .ward-table {

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
    padding-right: 50px;
    padding-left: 50px;
}

.ward-table-field-report {

    width: 100%;
    margin-top: 1rem;
    padding-right: 50px;
    padding-left: 50px;

}

.ward-head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 50px;
}

.ward-head-inner {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
}

.ward-head p {
    font-size: 12px;
}

.ward-head h1 {
    margin-top: 2rem;
    font-size: 17px;
    font-weight: bolder;
    text-decoration: underline black 3px;
}

.ward-head h1:nth-child(3) {
    margin-top: 2rem;
    font-size: 13px;
    font-weight: bolder;
}

.ward-content {
    margin-bottom: 2rem;
    padding-left: 50px;
}

.ward-info {
    display: flex;
    justify-content: space-between;
}

.ward-info div {
    width: 30%;
}

.ward-table,
.ward-table-field-report {
    border-color: black;
}



.scrollable-container {
  
    overflow-y: auto;
    margin: 10px;
    padding: 5px;
}


.mdb-table {
    border-color: black;
}

.mdb-table th {
    font-size: 12px;
    color: black;
}

.mdb-table td {
    height: 45px;
    font-size: 12px;
    color: black;
}

.mdb-table2 {
    border-color: black;
}

.mdb-table2 tr {
    height: 70px;
    width: 70px;
}

.mdb-table2 th {
    font-size: 12px;
    color: black;
    height: 70px;
    width: 70px;
}

.mdb-table2 td {
    text-align: left;
    font-size: 12px;
    color: black;
}

.text-sm-start {
    font-size: 12px;
    padding-left: 50px;
    color: black;
}

.data-collection-end {
    font-size: 12px;
    color: black;
}

.page-break {
    page-break-before: always;
}

.Data-collection-icon {
    display: flex;
    justify-content: flex-start;

    margin-left: 2rem;
    cursor: pointer;
}

.download-icon {

    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  
    margin-right: 2rem;

}

.Data-collection-dropdown-show {
    display: flex;
    justify-content: flex-start;

    margin-left: 2rem;


}




.data-collection-end {
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 10px;
}

.single-pdf-download .single-pdf-text {
    visibility: hidden;
}

.single-pdf-text {
    display: flex;
    justify-content: baseline;
    justify-content: center;
    font-size: 12px;
    width: 70px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
}


.single-pdf-download:hover .single-pdf-text {
    visibility: visible;
}

.multiple-pdf-download .multiple-pdf-text {
    visibility: hidden;
}

.multiple-pdf-text {
    display: flex;
    justify-content: baseline;
    justify-content: center;
    font-size: 12px;
    width: 70px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

}

.multiple-pdf-download:hover .multiple-pdf-text {
    visibility: visible;
}

.footer-line{
    border: 2px solid grey;
    width: 60%;
    margin-block: 3rem !important;
} */