.modelContainer {
  margin-top: 15%;
}
.fPassword {
  width: 100%;
  font-size: 2em;
  background: var(--secondary-color);
  text-align: center;
  margin-bottom: 20px;
  border-radius: 14px 14px 0 0;
  color: var(--white-color);
}
.fpanelheadbtn {
  /* background-color: var(--white-color) !important; */
  color: var(--white-color) !important;
  border: none;
  margin-top: -18px !important;
}
.fpanelheadbtn:hover {
  cursor: pointer;
}

@media only screen and (max-width: 480px) {
  .modelContainer {
    margin-top: 30%;
  }
}
@media only screen and (max-width: 380px) {
  .modelContainer {
    margin-top: 40%;
  }
}
