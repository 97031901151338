.ol-popup {
  position: absolute;
  z-index: 2;
  background-color: white;
  -webkit-filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  padding: 0px;
  border-radius: 15px;
  bottom: 12px;
  left: -50px;
  min-width: 280px;
}
.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}
.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}
.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
}
.ol-popup-closer:after {
  content: "✖";
}

.more_info {
  cursor: pointer;
}
.minimalInfoPopup {
  margin-bottom: 15px;
  width: 325px;
}
.minimalInfoPanelBody {
  padding: 10px;
  border: none !important;
  border-radius: none !important;
  max-height: calc(50vh - 79px);
  overflow-x: auto;
  overflow-y: auto;
}
.minimalInfoPanelBody .panel-body {
  border: none !important;
  border-radius: 0px !important;
}
.minimalinfoContent {
  margin-bottom: 3px;
  font-size: 12px !important;
  line-height: 20px;
}
.popupCloseButton {
  margin-top: -18px !important;
  margin-right: 0px !important;
  font-size: 12px !important;
  padding: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.minimalInfoHead {
  padding: 8px !important;
}
.mpanelheadbtn {
  background-color: var(--secondary-color) !important;
  color: var(--white-color) !important;
  border: none;
  margin-top: -14px !important;
  margin-left: 10px;
}
.mpanelheadbtn:hover {
  cursor: pointer;
}
.Btn_toolbar {
  margin-bottom: 3px !important;
}
.countbuttonMin {
  padding-top: 0.2em !important;
  padding-bottom: 0.09em !important;
  background-color: white !important;
  color: var(--primary-color) !important;
  height: 20px;
  margin-top: -18px !important;
}

.btn-success {
  color: var(--white-color) !important;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}
.btn-primary {
  color: var(--white-color) !important;
  background-color: var(--secondary-color) !important;
  border-color: var(--white-color) !important;
}
.panel-heading {
  background-color: var(--secondary-color) !important;
}
.panel-default .panel-heading {
  border-radius: 15px 15px 0 0;
  background-color: var(--secondary-color) !important;
  color: var(--white-color) !important;
  border: none !important;
}
.panel {
  /* border-radius: 15px; */
  border: none !important;
}
.panelheadbtn {
  background-color: var(--secondary-color) !important;
  color: var(--white-color) !important;
  border: none;
  margin-top: -14px !important;
}
.panelheadbtn:hover {
  cursor: pointer;
}
.table-condensed > tbody > tr > td {
  padding: 10px !important;
  /* word-break: break-word; */
  overflow-wrap: anywhere;
}

.minimal-btn-grp{
  display: flex;
    align-items: center;
    justify-content: center;
    gap: 13px;
}

.moreBtn {
  background-color: var(--primary-color);
  color: white;
  border: none;
  outline: none;
  /* float: right; */
  padding: 7 25px 7 25px;
  /* margin: 0 20 10 0; */
  /* position: relative;
  right: 45px; */
  border-radius: 2px;
}
.zoomBtn {
  color: var(--primary-color);
  background-color: var(--white-color);
  border: 1px solid var(--primary-color);
  outline: none;
  float: left;
  padding: 6 15px 6 15px;
  /* margin: 0 0 10 20; */
  /* position: absolute;
  left: 63px; */
  border-radius: 2px;
}

.streetBtn{
  background-color: var(--primary-color);
  color: white;
  border: none;
  outline: none;
  padding: 6 15px 6 15px;
}

.btnP {
  background-color: var(--white-color);
  border: 10px;
  color: var(--primary-color);
  /* padding: 4px 4px 4px; */
}
