.navbar-class {
  background-color: var(--primary-color);
  color: var(--white-color) !important;
  width: 100%;
  margin-bottom: 0px;
}

.navbar-brand {
  font-size: 25px;
  color: var(--white-color) !important;
}

.nav-link {
  color: var(--white-color) !important;
}

.navbar-inverse .navbar-nav>li>a {
  color: var(--white-color) !important;
}

.navbar-inverse {
  border-color: var(--primary-color);
}

.container-panel {
  overflow-x: scroll;
  width: 100%;
  height: 100%;
  padding-bottom: 20px;
}

.diagram-panel1 {
  width: 100%;
  text-align: center;
  /* height: 380px; */
  background-color: var(--white-color);
  border-radius: 12px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3), 0 2px 10px rgba(0, 0, 0, 0.22);
}

.diagram-panel2 {
  width: 100%;
  text-align: center;
  /* height: 380px; */
  background-color: var(--white-color);
  border-radius: 12px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3), 0 2px 10px rgba(0, 0, 0, 0.22);
}

.form-controls {
  margin-top: 6px !important;
  margin-bottom: 10px !important;
}

.dashboard-heading {
  margin-top: 0px !important;
  margin-bottom: 10px !important;
  margin-left: 10px !important;
  color: var(--secondary-color);
}

.resultProgressBarDiv {
  width: 100%;
  margin-top: 5px;
}

.resultProgressBar {
  width: 100;
  justify-content: center;
  margin: auto;
}

.count-panel {
  width: 100%;
  text-align: center;
  background-color: var(--white-color);
  border-radius: 12px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3), 0 2px 10px rgba(0, 0, 0, 0.22);
}

.count-panel-population {
  width: 100%;
  min-height: 50px !important;
  height: 50px;
  margin: 10px;
  text-align: center;
  background-color: var(--white-color);
  border-radius: 12px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3), 0 2px 10px rgba(0, 0, 0, 0.22);
}

.socio-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-map {
  position: relative;
  display: block;
  padding: 10px 15px;
}

/* Analysis Style */
/* .analysis-row{
    width: 100%;
    display: flex;
    justify-content: center;
    border-block: 4px solid grey;
}

.analysis-heading{
    font-size: 3rem;
    font-weight: 600;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.top-analysis-div{
  display: flex;
    justify-content: center;
}

.analysis-second-div{
    display: flex;
    gap: 1rem;
}
.analysis-dropdown-head{
  border-block: 4px solid #20130f;
  margin-bottom: 1rem;
} */
/* .underline{
    margin-top: 0.55rem;
    width: 15rem;
    height: 3px;
    background-color: black;
} */

.left-row {
  width: 15%;
  padding: 0.6rem;
}

.right-row {
  width: 15%;
  padding: 0.6rem;
}

.show-report-btn {
  margin-right: 4rem;
  width: 14%;
  padding: 0.8rem;
  background-color: #ec3131;
  outline: none;
  border: none;
  border-radius: 0.6rem;
  /* font-size: 1.6rem; */
  /* font-weight: 600; */
  color: white;
  cursor: pointer;
}

.show-report-btn:disabled {
  background-color: #df5c5c;
  cursor: not-allowed;
}

/* .div-bw-line{
  width: 90%;
  height: 3px;
  background-color: black;
} */

.about-div {}

.about-image-div {}

.about-image {
  height: 100%;
  width: 100%;
}

.about-discrtiption {}

.nav-tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 13px;
}

/* --- Second Dashboard --- */

.second-dashboard-main-row {
  height: 400px;
  margin: 10px 8px 0px 1px !important;
}

.second-dashboard-col {
  display: flex;
  flex-direction: column;
  /* grid-gap: 3px; */
  /* gap: 3px; */
  /* border: 1px solid; */
  padding: 0px !important;
}

.second-dashboard-btn {
  flex-direction: column !important;
  height: fit-content;
  width: 100%;
  border: 1px solid #c4bfbfb5 !important;
  display: flex;
  align-items: center;
  padding: 2px;
  overflow: hidden;
}

.second-dashboard-slider-range {
  width: 70% !important;
}

.second-dashboard-heading {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: 500;
}

.second-dashboard-heading-key {
  font-size: 11px;
  color: grey;
  letter-spacing: .5px;
}

.second-dashboard-heading-value {
  font-size: 11px;
  color: black;
  font-weight: 600;
  margin-left: 7px;
}

.second-dashboard-doughnuts-values {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1px;
}

.second-dashoard-doughnut-start-value {
  font-weight: bold;
  font-size: 2rem;
}

.second-dashoard-doughnut-center-value {
  font-weight: bold;
  font-size: 2rem;
  color: #a8a4a4;
}

.second-dashoard-doughnut-end-value {
  font-weight: bold;
  font-size: 2rem;
}

.second-dashboard-doorStatusBarChart {
  flex-direction: column !important;
  width: 100%;
  border: 1px solid #c4bfbfb5 !important;
  display: flex;
  align-items: center;
  padding: 2px;
  /* height: 256px; */
  height: 160px;
  justify-content: space-around;
}

.second-dashboard-row {
  margin: 0px !important;
}

.second-dashboard-row-col {
  padding: 0px !important;
}

.second-dashboard-pie-chart-row {
  margin: 0px !important;
}

.doughnut-container {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 170px;
  max-height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.doughnut-container canvas {
  max-width: 100%;
  max-height: 100%;
}

.pie-container {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 180px;
  max-height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pie-container canvas {
  max-width: 100%;
  max-height: 100%;
}

.bar-container {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 400px;
  max-height: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bar-container canvas {
  max-width: 100%;
  max-height: 100%;
}

/* ----------------- */

@media only screen and (min-width: 2560px) {
  .diagram-panel1 {
    /* height: 700px; */
  }

  .diagram-panel2 {
    /* height: 700px; */
  }
}

@media only screen and (min-width: 1024px) {
  .navbar-class {
    height: 45px;
  }
}

@media only screen and (max-width: 770px) {
  .diagram-panel1 {
    /* height: 400px; */
  }

  .diagram-panel2 {
    /* height: 380px; */
  }
}

@media only screen and (max-width: 425px) {
  .diagram-panel1 {
    /* height: 300px; */
  }

  .diagram-panel2 {
    /* height: 380px; */
  }
}

@media only screen and (max-width: 325px) {
  .diagram-panel1 {
    /* height: 200px; */
  }

  .diagram-panel2 {
    /* height: 310px; */
  }
}

@media (min-width: 768px) {
  .nav-map {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}