body {
  margin: 0;
  padding: 0;
}
:root {
  --primary-color: #ca2208;
  --secondary-color: #142240;
  --white-color: #fff;
  --blue: #3173ad;
  --green: #4ea64e;
  /* Layer Category */
  --layercategorytext: #282726;
  --hoverbg: #e3a09b;
  --plusopen: #282726;
  /* --white-color: #d8e0eb; */
  /* Scrollbar */
  --scroll: #d8e0eb;
}

.iconStyle {
  width: 25px;
  height: 25px;
}
