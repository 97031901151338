.advancedFilterModal {
  position: absolute;
  top: 50%;
  left: 28%;
  margin: -213px 0 0 -213px;
  width: 900px !important;
  border-radius: 15px;
  box-shadow: none !important;
  border: none !important;
}
.modal-backdrop.in {
  opacity: 0 !important;
}
.advancedFilterModal .modal.in .modal-dialog {
  width: 900px !important;
  border-radius: 15px !important;
}
.advancedFilterModal .modal-content {
  border-radius: 18px !important;
  width: 750px !important;
}
.advancedFilterPanel {
  margin-bottom: 0px;
  width: 900px;
}
.model-header {
  background-color: var(--secondary-color)!important;
  color: #fff;
  padding: 10px 15px;
  border-bottom: 1px solid transparent;

}
.modal-tile {
  font-size: 21px;
  margin: -4px
}
@media only screen and (max-width: 768px) {
  .advancedFilterModal {
    top: 50%;
    left: 50%;
    margin: -164px 0 0 -164px;
    overflow-x: scroll;
    width: 328px !important;
  }
  .advancedFilterModal .modal.in .modal-dialog {
    width: 312px !important;
  }
  .advancedFilterModal .modal-content {
    width: 312px !important;
  }
  .advancedFilterPanel {
    width: 312px !important;
  }
}

.panelBodys {
  /* max-height: calc(70vh - 170px); */
  min-height: 60px;
  /* overflow-y: auto; */
  border-radius: 15px !important;
  padding: 25px !important;
}

/* width */
/* ::-webkit-scrollbar {
  width: 12px;
} */
.report {
  margin-left: 3px;
}
.addfilterbtn {
  padding: 3px;
  border-color: var(white-color) !important;
  background-color: var(white-color) !important;
  margin-left: 15px !important;
  color: var(--white-color);
}
