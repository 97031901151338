.overlayPanel {
  z-index: 1;
  position: absolute;
  border-color: white !important;
  right: 20px;
  margin: auto;
  width: 300px;
  margin-top: 95px;
  border-radius: 20px;
}
.overlayPanelBody {
  max-height: calc(80vh - 80px);
  overflow-y: auto;
  padding: 0px;
}

@media (max-width: 992px) {
  .overlayPanel {
    z-index: 1;
    border: 1px solid black;
    position: absolute;
    border: 0px;
    right: 10%;
    left: 10%;
    bottom: 0% !important;
    width: 80%;
  }
  .overlayPanelBody {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
  }
}

.panel-heading button {
  margin-top: -26px;
  font-size: 15px;
}

/* 
::-webkit-scrollbar {
  width: 15px;
}


::-webkit-scrollbar-track {
  border-radius: 25px;
}


::-webkit-scrollbar-thumb {
  background-color: var(--scroll);
  border-radius: 25px;
}


::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar);
} */

* {
  scrollbar-width: thin;
  scrollbar-color: #d3d3dd #f2f5fb;
}
*::-webkit-scrollbar {
  width: 7px;
}
*::-webkit-scrollbar-track {
  background: #f2f5fb;
}

*::-webkit-scrollbar-thumb {
  background-color: #d3d3dd;
  border-radius: 20px;
  border: 1px solid #f2f5fb;
}

.btn-success {
  color: var(--white-color) !important;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}
.btn-primary {
  color: var(--white-color) !important;
  background-color: var(--secondary-color) !important;
  border-color: var(--white-color) !important;
}
.panel-default .panel-heading {
  border-radius: 15px 15px 0 0;
  background-color: var(--secondary-color) !important;
  color: var(--white-color) !important;
  border: none !important;
  margin-bottom: 0 !important;
}
.panel {
  border-radius: 15px 15px 15px 15px !important;
  border: none !important;
  min-height: 100px !important;
  /* box-shadow: 0px 0px 5px #00000047; */
}
.panelheadbtn {
  background-color: var(--secondary-color) !important;
  color: var(--white-color) !important;
  border: none;
  margin-top: -14px !important;
}
.panelheadbtn:hover {
  cursor: pointer;
}
.uncheck_all {
  margin-left: 82px !important;
  margin-right: 20px !important;
  margin-top: -23px !important;
  font-size: 12px !important;
  padding: 5px 3px 5px 3px !important;
  width: 105px;
  color: var(--primary-color);
  border-radius: 6px;
}
.cancel {
  color: var(--white-color);
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
  width: 14px;
  height: 14px;
}
